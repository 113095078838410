import React from 'react';

import { TableComponent } from './components/table';

import { ThemeLayout } from '../../layout/themeLayout';
import { Main, TableWrapper } from '../../container/styled';

import { PageHeader } from '../../components/page-headers/page-headers';
import { Cards } from '../../components/cards/frame/cards-frame';

import { useFetch } from '../../hooks/useFetch';
import { IFetchPropsSegments } from './types';
import { HiOutlineCurrencyDollar, HiOutlineServer } from 'react-icons/hi2';
import { RiCoinsLine } from 'react-icons/ri';
import { Box } from './styles';
import { useAuth } from '../../hooks/useAuth';
import { Alert } from 'antd';

export function Affiliate() {
  const {
    isFetching,
    dataFetch,
    refetch,
    currentPage,
    itensPerPage,
    setCurrentPage,
    setColumnOrdenation,
    setItensPerPage,
    totalItens,
  } = useFetch<IFetchPropsSegments>({ baseUrl: 'affiliate/sales', isLinkProps: false });

  const { dataFetch: afiliateData } = useFetch<any>({
    baseUrl: 'affiliate/sales-info',
    isLinkProps: false,
    isArray: false,
  });

  const { dataFetch: meData } = useFetch<any>({
    baseUrl: 'me',
    isLinkProps: false,
    isArray: false,
  });

  return (
    <ThemeLayout>
      {meData?.affiliate_actived === 0 && (
        <Alert
          type="warning"
          message="Atenção!"
          closable
          description="Seu cadastro como Afiliado ainda está pendente, e em breve você terá um retorno! Mas enquanto isso você pode testar nossa API, criando instâncias com teste gratuito de 3 dias!"
        />
      )}

      <PageHeader ghost title="Extrato (Afiliado)" />

      <div style={{ display: 'flex', flexWrap: 'wrap', alignItems: 'center', gap: '0rem', padding: '1rem' }}>
        <Box className="serv">
          <span>
            <HiOutlineServer />
          </span>

          <div>
            <h2>Total de Indicações</h2>
            <h3>
              {!dataFetch && '...'} {afiliateData?.total_sales}
            </h3>
          </div>
        </Box>
        <Box className="serv">
          <span>
            <RiCoinsLine />
          </span>

          <div>
            <h2>Total em Vendas</h2>
            <h3>{afiliateData?.total_amount.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</h3>
          </div>
        </Box>
        <Box className="check">
          <span>
            <HiOutlineCurrencyDollar />
          </span>

          <div>
            <h2>Disponível para Saque</h2>
            <h3>
              {afiliateData?.available_withdrawal.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}
            </h3>
          </div>
        </Box>
      </div>

      <Main>
        <Cards headless>
          {meData?.role === 2 && meData.affiliate_actived === 1 && (
            <p>
              <strong>Seu Link de Indicação:</strong> {meData?.affiliate_link}
              <p>
            <strong>OBS:</strong> os valores estarão disponíveis para saque após 30 dias da data de cada venda
              </p>
            </p>
           
          )}
          <TableWrapper className="table-order table-responsive">
            <TableComponent
              setCurrentPage={setCurrentPage}
              setColumnOrdenation={setColumnOrdenation}
              currentPage={currentPage}
              refetch={refetch}
              isLoading={isFetching}
              dataFetch={dataFetch}
              totalItens={totalItens}
              itensPerPage={itensPerPage}
              setItensPerPage={setItensPerPage}
            />
          </TableWrapper>
        </Cards>
      </Main>
    </ThemeLayout>
  );
}
