import React, { useEffect, useState } from "react";
import { Col, Radio } from "antd";

interface Props {
  applyFiltersNow: (event: {content: string; key: string}) => void;
  filters: {content: string; key: string}[];
}

export function StatusRadioGroup({ applyFiltersNow, filters }: Props) {
  const [state, setState] = useState("1");

  function onChange(event: string) {
    applyFiltersNow({ key: "&filter.isActive=", content: event})
    setState(event)
  }

  useEffect(() => {
    if(filters.length === 0){
      setState("3")
    }
  }, [filters])

  return (
    <Col lg={14} xs={24}>
      <div className="table-toolbox-menu">
        <span className="toolbox-menu-title"> Status:</span>

        <Radio.Group value={state} onChange={(e) => onChange(e.target.value)}>
          <Radio.Button value="0">
            Ativos
          </Radio.Button>
          <Radio.Button value="1">
            Inativos
          </Radio.Button>
        </Radio.Group>
      </div>
    </Col>
  );
}
