import styled from 'styled-components';
import { theme } from '../../../../config/theme/themeVariables';

export const Container = styled.div`
  background-color: #fff;
  border-radius: 0.5rem;

  flex: 1;

  header {
    padding: 1rem;
    display: flex;
    flex-direction: column;
    border-bottom: 1px solid ${theme['bg-color-deep']};
    h1 {
      font-size: 1.1rem;
      margin-bottom: 0;
      color: ${theme['heading-color']};
    }

    p {
      margin-bottom: 0;
      color: ${theme['gray-solid']};
    }
  }

  main {
    padding: 1rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  form {
    padding: 2rem 0;
    width: 100%;
    max-width: 450px;
  }

  .changes {
    display: flex;
    align-items: center;
    gap: 2rem;
    width: 100%;
    max-width: 450px;
  }
`;

export const Divider = styled.div`
  height: 1px;
  width: 100%;

  background-color: ${theme['bg-color-deep']};
`;
