import React, { useEffect, useState } from 'react';
import { ThemeLayout } from '../../layout/themeLayout';
import { Main } from '../../container/styled';
import { PageHeader } from '../../components/page-headers/page-headers';

import { LeftMenu } from './components/LeftMenu';
import { MyAccount } from './components/MyAccount';
import { Password } from './components/Password';
import { MyPlan } from './components/MyPlan';
import { History } from './components/History';
import { BillingInformation } from './components/BillingInformation';
import { useFetch } from '../../hooks/useFetch';
import { Spin } from 'antd';
import { useParams } from 'react-router-dom';
import Alert from '../../components/alerts/alerts';
import moment from 'antd/node_modules/moment';
import { useAuth } from '../../hooks/useAuth';

interface fetchProps {
  billing_information: null;
  email: string;
  expire_at: string;
  name: string;
  plan_id: 1;
  plan_qty: string;
  prices: any;
  plan_name: string;
  whatsapp_contact: null | string;
  whatsapp_notifications: null | string;
}

export function ProfilePage() {
  const params = useParams<any>();
  const [pageNumber, setPageNumber] = useState(Number(params?.inititial) || 0);

  useEffect(() => {
    setPageNumber(Number(params?.inititial) || 0);
  }, [params]);

  const { dataFetch, isFetching, refetch } = useFetch<fetchProps>({
    baseUrl: 'me',
    isLinkProps: false,
    isArray: false,
  });

  const name = (e: number) => {
    switch (e) {
      case 0:
        return 'Configurações - > Minha conta';

      case 1:
        return 'Configurações - > Trocar Senha';

      case 2:
        return 'Configurações - > Meu Plano';

      case 3:
        return 'Configurações - > Histórico de pagamentos';

      case 4:
        return 'Configurações - > Informações de cobrança';
    }
  };

  const isExpirado = new Date(dataFetch?.expire_at || '') < new Date();
  const isCancelado = !dataFetch?.plan_name;
  const depois = moment(new Date(dataFetch?.expire_at || ''));
  const antes = moment(new Date());

  const { user } = useAuth();

  return (
    <ThemeLayout>
      {user?.plan_type === 1 && (
        <>
          {isExpirado && !isCancelado && user?.plan_type === 1 && (
            <Alert type="error" message="Erro" description="Seu plano expirou" />
          )}
          {isCancelado && !!dataFetch?.plan_name && user?.plan_type === 1 && (
            <Alert type="error" message="erro" description="Seu plano foi cancelado" />
          )}

          {dataFetch?.plan_name === 'Teste Grátis (1 instância)' && !isExpirado && (
            <Alert
              type="warning"
              message="Aviso"
              description={`Atenção! Sua conta de teste gratuita irá expirar em ${depois.diff(
                antes,
                'days',
              )} dias! Faça já o upgrade de seu plano para continuar utilizando as instâncias`}
            />
          )}
        </>
      )}

      <PageHeader title={name(pageNumber)} />

      <Main>
        <div style={{ display: 'flex', flexWrap: 'wrap', alignItems: 'start', flexDirection: 'row', gap: '1rem' }}>
          <LeftMenu numberPage={pageNumber} setNumberPage={setPageNumber} />
          {isFetching && (
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                flex: 1,
                height: '15rem',
              }}
            >
              <Spin />
            </div>
          )}

          {pageNumber === 0 && dataFetch && (
            <MyAccount
              refetch={refetch}
              data={{
                email: dataFetch?.email,
                name: dataFetch?.name,
                whatsapp_contact: dataFetch.whatsapp_contact,
                whatsapp_notifications: dataFetch.whatsapp_notifications,
              }}
            />
          )}
          {pageNumber === 1 && dataFetch && <Password />}
          {pageNumber === 2 && dataFetch && (
            <MyPlan
              expire_at={dataFetch.expire_at}
              plan_name={dataFetch.plan_name}
              plan_qty={Number(dataFetch.plan_qty)}
              prices={dataFetch.prices}
              billing_information={dataFetch.billing_information}
            />
          )}
          {pageNumber === 3 && dataFetch && <History />}
          {pageNumber === 4 && dataFetch && (
            <BillingInformation
              refetch={refetch}
              data={{
                name: dataFetch?.name,
                billing_information: dataFetch?.billing_information,
              }}
            />
          )}
        </div>
      </Main>
    </ThemeLayout>
  );
}
