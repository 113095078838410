import React, { useState } from 'react';
import { Form, Input, Button } from 'antd';

import Heading from '../../components/heading/heading';

import { AuthWrapper } from './styles';
import AuthLayout from '../../container/profile/authentication/Index';
import { api } from '../../services/api';
import toast from 'react-hot-toast';

function Forgot() {
  const [isLoading, setIsLoading] = useState(false);

  async function handleSubmit(data: { email: string }) {
    setIsLoading(true);
    const res = await api.post('/forgot-password', data);
    setIsLoading(false);

    if (res.data.status === 200) {
      toast.success(res.data.message);
    } else {
      toast.error(res.data.message);
    }
  }

  return (
    <AuthWrapper>
      <div className="auth-contents">
        <Form onFinish={handleSubmit} layout="vertical">
          <Heading as="h3">
            Recupere sua <span className="color-secondary">conta</span>
          </Heading>

          <Form.Item
            name="email"
            rules={[{ message: 'Digite um Email Válido!', required: true }]}
            initialValue=""
            label="Email"
          >
            <Input placeholder="Email" />
          </Form.Item>

          <Form.Item style={{ flex: 1 }}>
            <Button className="btn-signin" htmlType="submit" type="primary" size="large">
              {isLoading ? 'Enviando...' : 'Enviar'}
            </Button>
          </Form.Item>
        </Form>
      </div>
    </AuthWrapper>
  );
}

export default AuthLayout(Forgot);
