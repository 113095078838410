import React from 'react';
import { Provider } from 'react-redux';
import { CookiesProvider } from 'react-cookie';
import { BrowserRouter } from 'react-router-dom';

import { AuthProvider } from './context/AuthContext';
import { LenguageProvider } from './context/LenguageContext';

import { Routes } from './routes';
import store from './redux/store';

import { ThemeProvider } from 'styled-components';

import config from './config/config';
import './static/css/style.css';
import { ConfigProvider, Empty } from 'antd';
const { theme } = config;

export const CustomizeRenderEmpty = () => (
  <div style={{ textAlign: 'center' }}>
    <Empty description={<p>Nenhum Registro</p>} image={Empty.PRESENTED_IMAGE_SIMPLE} />
  </div>
);

const AppComponent = () => {
  return (
    <BrowserRouter>
      <CookiesProvider>
        <ConfigProvider renderEmpty={CustomizeRenderEmpty}>
          <AuthProvider>
            <LenguageProvider>
              <ThemeProvider theme={{ ...theme }}>
                <Routes />
              </ThemeProvider>
            </LenguageProvider>
          </AuthProvider>
        </ConfigProvider>
      </CookiesProvider>
    </BrowserRouter>
  );
};

export function App() {
  return (
    <Provider store={store}>
      <AppComponent />
    </Provider>
  );
}
