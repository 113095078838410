export const lenguageIngles = {
  home: {
    title: 'Hello, welcome',
  },

  contacts: {
    title: 'Contacts',
  },

  payments: {
    title: 'Payments',
  },
};
