import React, { useRef, useState } from 'react';

import { Button, Form as AntdForm, Input, Select } from 'antd';
import { UploadFile } from 'antd/lib/upload/interface';
import toast from 'react-hot-toast';
import { UploadComponent } from '../../../components/UploadComponent';
import { api } from '../../../services/api';
import { CardWrapper, FormWrapper } from '../styles';
import FeatherIcon from 'feather-icons-react';

import { IHandleSubmitProps } from '../types';

// import { MultiSelect } from "../../../components/MultiSelect";

interface IFormInitialFields {
  name: string;
  status: number;
  event_name: string;
  message: string;
  file: string;
}

interface IFormHortmartEvent {
  formType: 'put' | 'post';
  initialFields?: IFormInitialFields;
  refetch: () => void;
  hortmartEventId?: string;
  setModalIsOpen: (event: boolean) => void;
}

export function Form({
  formType,
  setModalIsOpen,
  refetch,
  hortmartEventId,
  initialFields = { name: '', status: 0, event_name: '', file: '', message: '' },
}: IFormHortmartEvent) {
  const [isLoading, setIsLoading] = useState(false);
  const [file, setFile] = useState<UploadFile[]>([] as UploadFile[]);

  const formRef = useRef<any>(null);

  const { TextArea } = Input;

  function clearFieldsAndCloseModal() {
    setModalIsOpen(false);
  }

  console.log(initialFields);

  async function handleSubmit(data: IHandleSubmitProps) {
    setIsLoading(true);

    if (!initialFields.file) {
      if (!file[0]) {
        toast.error('É necessário enviar um arquivo');
        setIsLoading(false);
        return;
      }
    }

    const formData = {
      name: data.name,
      event_name: data.event_name,
      file: data.file,
      message: data.message,
      status: data.status,
    };

    try {
      await api.put(`/hotmartEvents/${hortmartEventId}`, formData);
      setModalIsOpen(false);
      setFile([]);
      formRef.current.resetFields();
    } catch (err) {
      const error: any = err;
      toast.error(error.response.data.message);
    }

    setIsLoading(false);
    refetch();
  }

  return (
    <FormWrapper>
      <AntdForm
        name="basic"
        onFinish={handleSubmit}
        style={{ padding: '30px' }}
        layout="vertical"
        initialValues={initialFields}
        ref={formRef}
      >
        <CardWrapper type="inner" title="Informações Gerais">
          <AntdForm.Item label="Nome" name="name" rules={[{ required: true, message: 'Campo obrigatório' }]}>
            <Input placeholder="Nome" />
          </AntdForm.Item>

          <AntdForm.Item label="Status" name="status" rules={[{ required: true, message: 'Campo obrigatório' }]}>
            <Select
              options={[
                {
                  value: 1,
                  label: 'ATIVO',
                },
                {
                  value: 0,
                  label: 'INATIVO',
                },
              ]}
            />
          </AntdForm.Item>

          <AntdForm.Item label="Mensagem" name="message" rules={[{ required: true, message: 'Campo obrigatório' }]}>
            <TextArea />
          </AntdForm.Item>

          <UploadComponent setFileList={setFile} fileList={file} />

          {initialFields.file && !file[0] && (
            <p style={{ fontSize: '13px', marginTop: '0.5rem' }}>
              <FeatherIcon icon="paperclip" size={13} /> {initialFields.file}{' '}
            </p>
          )}
        </CardWrapper>

        <footer style={{ padding: '20px 30px', borderTop: '1px solid #f0f0f0' }}>
          <Button type="default" htmlType="button" onClick={clearFieldsAndCloseModal}>
            Voltar
          </Button>

          <Button type="primary" htmlType="submit" loading={isLoading}>
            Salvar
          </Button>
        </footer>
      </AntdForm>
    </FormWrapper>
  );
}
