import React, { useEffect } from 'react';
import Cookie from 'js-cookie';
import { Route, Switch, useLocation, useHistory } from 'react-router-dom';

import { ProtectedRoute } from '../components/utilities/protectedRoute';

import SignIn from '../pages/SignIn';
import SignUp from '../pages/SignUp';
import { Contacts } from '../pages/contacts';
import { Segments } from '../pages/segments';
import { Campaigns } from '../pages/campaigns';
import { Instances } from '../pages/instances';
import { UserInteration } from '../pages/interations/UserInterations';
import { Interations } from '../pages/interations/AdminInterations';
import { FaqAdmin } from '../pages/faq/AdminFaq/index';
import { ProfilePage } from '../pages/profilepage';
import { hortmartEvents } from '../pages/hotmartEvents';
import { homarts } from '../pages/hotmart';
import Faq from '../pages/faq/UserFaq';
import Video from '../pages/video';
import { ViewInstanceDetails } from '../pages/viewInstanceDetails';
import { Plugins } from '../pages/plugins/AdminInterations';
import { UserPlugin } from '../pages/plugins/UserInterations';
import SignUpAffiliate from '../pages/SignUpAffiliate';
import { Affiliate } from '../pages/affiliate';
import { Payments } from '../pages/Admin/payments';
import { Instance } from '../pages/Admin/instances';
import { Company } from '../pages/Admin/company';
import { useAuth } from '../hooks/useAuth';
import Forgot from '../pages/Forgot';
import ResetPassword from '../pages/ResetPassword';
import { Plans } from '../pages/Admin/plans';
import { Users } from '../pages/Admin/users';

export function Routes() {
  const location = useLocation();
  const history = useHistory();
  const auth = useAuth();

  console.log(auth.user);
  const token = Cookie.get('whats-front-token');

  useEffect(() => {
    if (location.pathname === '/' && token) {
      history.push('/instances');
    }
  }, [history, token, location.pathname]);

  return (
    <Switch>
      <Route exact path="/" component={SignIn} />
      <Route path="/signUp" component={SignUp} />
      <Route path="/forgot" component={Forgot} />
      <Route path="/reset-password" component={ResetPassword} />
      <Route path="/signup-affiliate" component={SignUpAffiliate} />
      <ProtectedRoute path="/admin/affiliate" component={Affiliate} />
      <ProtectedRoute path="/admin/payments" component={Payments} />
      <ProtectedRoute path="/admin/instances" component={Instance} />
      <ProtectedRoute path="/admin/plans" component={Plans} />
      <ProtectedRoute path="/admin/users" component={Users} />
      <ProtectedRoute path="/admin/company" component={Company} />
      <ProtectedRoute path="/profile/:inititial" component={ProfilePage} />
      <ProtectedRoute path="/video" component={Video} />
      <ProtectedRoute path="/faq" component={Faq} />
      <ProtectedRoute path="/integrations" component={UserInteration} />
      <ProtectedRoute path="/plugins" component={UserPlugin} />
      <ProtectedRoute exact path="/instances" component={Instances} />
      <ProtectedRoute path="/instances/:id" component={ViewInstanceDetails} />
      <ProtectedRoute path="/admin/contacts" component={Contacts} />
      <ProtectedRoute path="/admin/events/:product_id" component={hortmartEvents} />
      <ProtectedRoute path="/admin/hotmart" component={homarts} />
      <ProtectedRoute path="/admin/faq" component={FaqAdmin} />
      <ProtectedRoute path="/admin/segments" component={Segments} />
      <ProtectedRoute path="/admin/interations" component={Interations} />
      <ProtectedRoute path="/admin/plugins" component={Plugins} />
      <ProtectedRoute path="/admin/campaigns" component={Campaigns} />
    </Switch>
  );
}
