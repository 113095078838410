import React from 'react';
import { Menu } from 'antd';
import { Link, useLocation } from 'react-router-dom';
import FeatherIcon from 'feather-icons-react';
import { useAuth } from '../../hooks/useAuth';

interface IMenuItensProps {
  topMenu: boolean;
}

export function MenuItens({ topMenu }: IMenuItensProps) {
  const location = useLocation();
  const { user } = useAuth();

  return (
    <Menu theme="light" defaultSelectedKeys={[location.pathname]}>
      {!topMenu && user?.role === 1 && <p className="sidebar-nav-title">ADMIN</p>}
      {user?.role === 1 && (
        <Menu.Item
          key="/admin/plans"
          icon={
            <Link className="menuItem-iocn" to={'/admin/plans'}>
              <FeatherIcon icon="smartphone" />
            </Link>
          }
        >
          <Link to={'/admin/plans'}>Planos</Link>
        </Menu.Item>
      )}
      {user?.role === 1 && (
        <Menu.Item
          key="/admin/instances"
          icon={
            <Link className="menuItem-iocn" to={'/admin/instances'}>
              <FeatherIcon icon="smartphone" />
            </Link>
          }
        >
          <Link to={'/admin/instances'}>Instâncias</Link>
        </Menu.Item>
      )}
      {user?.role === 1 && (
        <Menu.Item
          key="/admin/payments"
          icon={
            <Link className="menuItem-iocn" to={'/admin/payments'}>
              <FeatherIcon icon="smartphone" />
            </Link>
          }
        >
          <Link to={'/admin/payments'}>Pagamentos</Link>
        </Menu.Item>
      )}
      {user?.role === 1 && (
        <Menu.Item
          key="/admin/users"
          icon={
            <Link className="menuItem-iocn" to={'/admin/users'}>
              <FeatherIcon icon="smartphone" />
            </Link>
          }
        >
          <Link to={'/admin/users'}>Usuários</Link>
        </Menu.Item>
      )}

      {!topMenu && (user?.role === 0 || user?.role === 2 || user?.role === 1) && (
        <p className="sidebar-nav-title">API</p>
      )}
      {user?.role === 0 && (
          <Menu.Item
            key="/instances"
            icon={
              <Link className="menuItem-iocn" to={'/instances'}>
                <FeatherIcon icon="smartphone" />
              </Link>
            }
          >
            <Link to={'/instances'}>Instâncias</Link>
          </Menu.Item>
        )}

{user?.role === 2 && (
          <Menu.Item
            key="/instances"
            icon={
              <Link className="menuItem-iocn" to={'/instances'}>
                <FeatherIcon icon="smartphone" />
              </Link>
            }
          >
            <Link to={'/instances'}>Instâncias</Link>
          </Menu.Item>
        )}

      {user?.role === 2 && (
        <Menu.Item
          key="/admin/affiliate"
          icon={
            <Link className="menuItem-iocn" to={'/admin/affiliate'}>
              <FeatherIcon icon="smartphone" />
            </Link>
          }
        >
          <Link to={'/admin/affiliate'}>Afiliado</Link>
        </Menu.Item>
      )}

      {(user?.role === 0 || user?.role === 2 || user?.role === 1) && (
        <Menu.Item
          key="/plugins"
          icon={
            <Link className="menuItem-iocn" to={'/plugins'}>
              <FeatherIcon icon="grid" />
            </Link>
          }
        >
          <Link to={'/plugins'}>Plugins</Link>
        </Menu.Item>
      )}

      {(user?.role === 0 || user?.role === 2 || user?.role === 1) && (
        <Menu.Item
          key="/documentacao"
          icon={
            <a className="menuItem-iocn" href="https://docs.cloudzapi.com" target="_blank">
              <FeatherIcon icon="file-text" />
            </a>
          }
        >
          <a rel="noopener noreferrer" href="https://docs.cloudzapi.com" target="_blank">
            Documentação API
          </a>
        </Menu.Item>
      )}

      {/*  {!topMenu && user?.role === 0 || user?.role === 2 && <p className="sidebar-nav-title">Integrações</p>} */}

      {/*   {user?.role === 0 || user?.role === 2 && (
        <Menu.Item
          key="/integrations"
          icon={
            <Link className="menuItem-iocn" to={'/integrations'}>
              <FeatherIcon icon="server" />
            </Link>
          }
        >
          <Link to={'/integrations'}>Integrações</Link>
        </Menu.Item>
      )} */}

      {!topMenu && (user?.role === 0 || user?.role === 2 || user?.role === 1) && (
        <p className="sidebar-nav-title">Ajuda</p>
      )}

      {(user?.role === 0 || user?.role === 2 || user?.role === 1) && (
        <Menu.Item
          key="/faq"
          icon={
            <Link className="menuItem-iocn" to={'/faq'}>
              <FeatherIcon icon="help-circle" />
            </Link>
          }
        >
          <Link to={'/faq'}>Perquntas e Respostas</Link>
        </Menu.Item>
      )}

      {(user?.role === 0 || user?.role === 2 || user?.role === 1) && (
        <Menu.Item
          key="/documentacao"
          icon={
            <a className="menuItem-iocn" href="https://wa.me/553531971727" target="_blank">
              <FeatherIcon icon="message-circle" />
            </a>
          }
        >
          <a rel="noopener noreferrer" href="https://wa.me/553531971727" target="_blank">
            Suporte
          </a>
        </Menu.Item>
      )}
      {!topMenu && (user?.role === 0 || user?.role === 2 || user?.role === 1) && (
        <p className="sidebar-nav-title">Configurações</p>
      )}

      {(user?.role === 0 || user?.role === 2 || user?.role === 1) && (
        <Menu.Item
          key="/profile/0"
          icon={
            <Link className="menuItem-iocn" to={'/profile/0'}>
              <FeatherIcon icon="user" />
            </Link>
          }
        >
          <Link to={'/profile/0'}>Minha Conta</Link>
        </Menu.Item>
      )}
    </Menu>
  );
}
