import React, { useState } from 'react';
import { Row, Col, Collapse } from 'antd';
import { Link } from 'react-router-dom';
import FeatherIcon from 'feather-icons-react';
import { Badge, ContainerWrapper, FaqCategoryBox, FaqWrapper } from './styles';
import { PageHeader } from '../../../components/page-headers/page-headers';
import { Cards } from '../../../components/cards/frame/cards-frame';
import Heading from '../../../components/heading/heading';
import { Button } from '../../../components/buttons/buttons';
import { Main } from '../../../container/styled';
import { ThemeLayout } from '../../../layout/themeLayout';
import { useFetch } from '../../../hooks/useFetch';
import { number } from 'yup/lib/locale';
import { reducer } from 'react-hot-toast/dist/core/store';

const { Panel } = Collapse;

const customPanelStyle = {
  background: '#ffffff',
  borderRadius: 5,
  marginBottom: 0,
  padding: 0,
  border: '1px solid #F1F2F6',
};

type faqFetchProps = {
  id: number;
  createdAt: string;
  updatedAt: string;
  title: string;
  content: string;
  faqCategoryId: number;
};

type faqCategoriesFetchProps = {
  id: number;
  title: string;
};

export function Faq() {
  const { dataFetch, filters, handleAddFiltersWithApplyFilters } = useFetch<faqFetchProps[]>({ baseUrl: '/faqs' });
  const { dataFetch: dataFetchCategories } = useFetch<faqCategoriesFetchProps[]>({ baseUrl: '/faqs-categories' });
  const handleChange = e => {
    e?.preventDefault();
    e?.target
      ?.closest('ul')
      ?.querySelector('a.active')
      ?.classList.remove('active');
    e?.target?.classList?.add('active');

    handleAddFiltersWithApplyFilters({ content: e.target.id, key: 'filter.faqCategoryId=' });
  };

  return (
    <ThemeLayout>
      <PageHeader ghost title="Perguntas Frequentes" />
      <Main>
        <ContainerWrapper>
          <FaqCategoryBox className="a1">
            <Cards headless title="Tópicos">
              {/*  <Badge className="faq-badge">Categorias</Badge> */}

              <ul>
                {dataFetchCategories?.map(item => (
                  <li>
                    <Link id={String(item.id)} className="primary" onClick={handleChange} to="#">
                      {item.title}
                    </Link>
                  </li>
                ))}
              </ul>
            </Cards>
          </FaqCategoryBox>

          <FaqWrapper className="a2">
            <Cards headless title="Perguntas e Respostas">
              <Collapse
                bordered={false}
                defaultActiveKey={['1']}
                expandIcon={({ isActive }) => <FeatherIcon icon={isActive ? 'minus' : 'plus'} size={14} />}
              >
                {dataFetch?.map(item => (
                  <Panel key={item.id} header={item.title} style={customPanelStyle}>
                    <p style={{ margin: 0, padding: 0 }} dangerouslySetInnerHTML={{ __html: item.content }}></p>
                  </Panel>
                ))}
              </Collapse>
            </Cards>
          </FaqWrapper>
        </ContainerWrapper>
      </Main>
    </ThemeLayout>
  );
}

export default Faq;
