import Styled from 'styled-components';
import { Card } from 'antd';

export const CardWrapper = Styled(Card)`
  header {
    display: flex;
   
    justify-content: space-between;
    align-items: center;
    margin-bottom: 16px;
  }

  .left-header {
    display: flex;
    align-items: center;
    background: red;

    h4 {
      margin: 0;
      margin-right: 16px;
    }

    button {
      margin-right: 16px;
    }
  }

  .actions {
    button {
      margin-left: 14px;
    }
  }
`;

export const FiltersWrapper = Styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;

  .ant-picker {
    width: 100%;
  }

  .ant-picker-input input {
    padding: 10px;
  }

  footer {
    display: flex;
    flex-direction: column;

    button {
      margin-bottom: 8px;
    }
  }
`;

export const FormWrapper = Styled.div`
  .ant-picker {
    width: 100%;
  }

  .ant-form-item-has-error .ant-picker {
    border-color: red !important;
  }

  .ant-picker-input input {
    padding: 10px;
  }

  footer {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-top: 30px;

    button {
      margin-left: 16px;
    }
  }
`;

export const Box = Styled.div`
  display: flex;
  white-space: nowrap;
  align-items: center;
  background-color: #fff;
  padding: 1rem;
  border-radius: 0.5rem;
  flex: 1;
  margin: 20px;
  gap: 1rem;
  border-radius: 0.5rem;
  border-color: #dfe4ea;
  border-style: solid;
  border-width: thin;

  span {
    width: 4rem;
    height: 4rem;
    border-radius: 0.3rem;

    display: flex;
    align-items: center;
    justify-content: center;

    svg {
      font-size: 1.5rem;
    }
  }

  h2 {
    margin: 0;
    font-size: 1rem;
    font-weight: 500;
    color: #646464;
  }

  h3 {
    font-size: 1.3rem;
    margin: 0;
    font-weight: 600;
  }

  &.serv {
    span {
      background-color: rgba(161, 200, 238, 0.4);
      color: #2c99ff;
    }
  }

  &.check {
    span {
      background-color: rgba(193, 234, 214, 0.4);
      color: #00c182;
    }
  }

  &.power {
    span {
      background-color: rgba(247, 192, 195, 0.4);
      color: #f5222d;
    }
  }
`;
