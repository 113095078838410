import React from 'react';
import moment from 'moment';
import { ColumnsType } from 'antd/lib/table';

import { IInterationsProps } from '../../types';
import { TableButtonDelete } from './TableButtonDelete';
import { TableButtonEdit } from './TableButtonEdit';

interface ITableColumnControllerProps {
  handleDeleteFunction: (tId: string) => Promise<void>;
  refetch: () => void;
  dataList?: IInterationsProps[];
}

export function TableColumnController({ handleDeleteFunction, refetch, dataList }: ITableColumnControllerProps) {
  const columns: ColumnsType<IInterationsProps> = [
    {
      title: 'Nome',
      dataIndex: 'name',
      sorter: true,
    },

    {
      title: 'Texto',
      dataIndex: 'description',
      sorter: true,
    },

    {
      title: 'Data de criação',
      dataIndex: 'createdAt',
      sorter: true,
    },

    {
      title: 'Ações',
      dataIndex: 'actions',
      render: (_, item) => {
        return (
          <div className="table-actions">
            <TableButtonEdit interationId={String(item.id)} refetch={refetch} data={item} />

            <TableButtonDelete handleDeleteFunction={handleDeleteFunction} contactId={String(item.id)} />
          </div>
        );
      },
    },
  ];

  const data: any[] = [];

  dataList?.forEach(item => {
    data.push({
      id: item.id,
      key: item.id,
      name: item.name,
      description: item.description,
      image: item.image,
      url: item.url,
      createdAt: moment(item.createdAt).format('DD/MM/YYYY'),
    });
  });

  return { columns, data };
}
