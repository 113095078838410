import { Button, Form, Input, Modal } from 'antd';
import React, { useEffect, useState } from 'react';
import { Container } from './styles';
import { theme } from '../../../../config/theme/themeVariables';
import toast from 'react-hot-toast';
import { api } from '../../../../services/api';
import { CardWrapper } from '../../../contacts/styles';
import { useHistory } from 'react-router-dom';

interface MyPlanProps {
  expire_at: string;
  plan_qty: number;
  prices: any;
  plan_name: string;
  billing_information: any;
}

export function MyPlan({ expire_at, plan_qty, prices, plan_name, billing_information }: MyPlanProps) {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [saving, setSaving] = useState(false);
  const [itemPrice, setItemPrice] = useState(0);
  const [qty, setQty] = useState(plan_qty || 0);

  useEffect(() => {
    const pricesArray = Object.entries(prices);
    const pricesArrayNumber: any = pricesArray.map(item => [Number(item[0]), item[1]]);

    let priceValue = pricesArrayNumber[0][1];
    pricesArrayNumber.forEach(item => {
      if (qty >= item[0]) priceValue = item[1];
    });

    setItemPrice(priceValue);
  }, [prices, qty]);
  const [modalCorrect, setModalCorrect] = useState(false);

  function submit() {
    setSaving(true);
    const { address, postal_code, city, country, cpf, cnpj, district, number, state } = billing_information;
    if (!address || !postal_code || !city || !country || !district || !number || !state) {
      setModalCorrect(true);
      return;
    }
    if (cpf || cnpj) {
      api
        .post('choose-plan', { qty })
        .then(res => {
          toast.success(res.data.message);
          window.open(res.data.url_redirect, '_blank');
        })
        .catch(res => toast.error(res.data.message))
        .finally(() => {
          setSaving(false);
          setModalIsOpen(false);
        });
    } else {
      setModalCorrect(true);
      return;
    }
  }

  function cancelAssin() {
    setSaving(true);
    api
      .post('/cancel-plan')
      .then(res => toast.success(res.data.message))
      .catch(res => toast.error(res.data.message))
      .finally(() => setSaving(false));
  }

  const isExpirado = new Date(expire_at) < new Date();
  const history = useHistory();
  return (
    <Container>
      <header>
        <h1>Meu plano</h1>
      </header>

      <Modal
        visible={modalCorrect}
        okText={'Ok'}
        cancelText="Cancelar"
        onOk={() => history.push('/profile/4')}
        onCancel={() => setModalCorrect(false)}
      >
        Antes de completar sua assinatura, preencha as informações de cobrança. Clique Aqui para preencher
      </Modal>

      <main>
        <p>
          <strong>Plano atual:</strong>
          {plan_name}
        </p>
        <p>
          <strong>Termina em:</strong>
          <span style={{ color: isExpirado ? 'red' : '' }}>
            {new Date(expire_at).toLocaleDateString()} {isExpirado && '(expirado)'}
          </span>
        </p>

        <div className="changes">
          <Button type="primary" size="large" onClick={() => setModalIsOpen(true)}>
            {plan_name === 'Teste Grátis (1 instância)' ? 'Assinar' : 'Trocar plano'}
          </Button>

          {plan_name !== 'Teste Grátis (1 instância)' && (
            <Button size="large" onClick={cancelAssin}>
              Cancelar assinatura
            </Button>
          )}
        </div>
      </main>

      <Modal
        visible={modalIsOpen}
        onCancel={() => setModalIsOpen(false)}
        bodyStyle={{ padding: 0 }}
        footer={null}
        closable={false}
      >
        <Form
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'stretch',
            justifyContent: 'center',
          }}
          layout="vertical"
        >
          <CardWrapper
            style={{
              marginTop: '1rem',
              margin: '20px',
            }}
            type="inner"
            title={`Editar plano`}
          >
            <Form.Item style={{ marginTop: '2rem' }} label="Número de Instâncias:">
              <Input type="number" min={0} value={qty} onChange={e => setQty(Number(e.target.value))} />
            </Form.Item>
            <Form.Item label={'Valor do plano'}>
              <span style={{ color: theme['primary-color'] }}>R$ {itemPrice * qty} / mês</span>
            </Form.Item>
          </CardWrapper>

          <footer
            style={{
              gap: '5px',
              padding: '20px',
              display: 'flex',
              justifyContent: 'end',
              borderTop: '1px solid #f0f0f0',
            }}
          >
            <Button type="default" htmlType="button" onClick={() => setModalIsOpen(false)}>
              Cancelar
            </Button>
            <Button type="primary" htmlType="button" onClick={submit}>
              {saving ? 'Salvando' : 'Salvar'}
            </Button>
          </footer>
        </Form>
      </Modal>
    </Container>
  );
}
