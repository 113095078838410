import { ColumnsType } from 'antd/lib/table';
import moment from 'moment';
import React from 'react';

import { Button } from '../../../../../components/buttons/buttons';
import { ICampaingsProps } from '../../types';

import FeatherIcon from 'feather-icons-react';
import { Link } from 'react-router-dom';

interface ITableColumnControllerProps {
  handleDeleteFunction: (tId: string) => Promise<void>;
  refetch: () => void;
  dataList?: ICampaingsProps[];
}

export function TableColumnController({ dataList }: ITableColumnControllerProps) {
  const columns: ColumnsType<ICampaingsProps> = [
    {
      title: 'ID',
      dataIndex: 'id',
      sorter: true,
    },

    {
      title: 'Nome',
      dataIndex: 'name',
      sorter: true,
    },

    {
      title: 'Data de criação',
      dataIndex: 'createdAt',
      sorter: true,
    },

    {
      title: 'Ações',
      dataIndex: 'actions',
      render: (_, item) => {
        return (
          <div className="table-actions">
            <Link to={`/admin/company/settings/${item.id}` + item.flowId}>
              <Button className="btn-icon" type="info" to="#" shape="circle">
                <FeatherIcon icon="edit" size={16} />
              </Button>
            </Link>
          </div>
        );
      },
    },
  ];

  const data: any[] = [];

  dataList?.forEach(item => {
    data.push({
      key: item.id,
      id: item.id,
      name: item.name,
      createdAt: moment(item.createdAt).format('DD/MM/YYYY'),
      flowId: item.flowId,
    });
  });

  return { columns, data };
}
