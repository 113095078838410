export const lenguagePortugues = {
  home: {
    title: 'Olá, bem vindo(a)',
  },

  contacts: {
    title: 'Contatos',
  },

  payments: {
    title: 'Pagamentos',
  },
};
