import React from 'react';
import { ColumnsType } from 'antd/lib/table';

import { TableButtonDelete } from './TableButtonDelete';
import { TableButtonEdit } from './TableButtonEdit';

interface ITableColumnControllerProps {
  handleDeleteFunction: (tId: string) => Promise<void>;
  refetch: () => void;
  dataList?: any[];
}

export function TableColumnController({ handleDeleteFunction, refetch, dataList }: ITableColumnControllerProps) {
  const columns: ColumnsType<any> = [
    {
      title: 'Nome',
      dataIndex: 'name',
      sorter: true,
    },

    {
      title: 'Email',
      dataIndex: 'email',
      sorter: true,
    },

    {
      title: 'Telefone',
      dataIndex: 'whatsapp_contact',
      sorter: true,
    },

    {
      title: 'Criado Em',
      dataIndex: 'created_at',
      sorter: true,
    },

    {
      title: 'Ações',
      dataIndex: 'actions',
      render: (_, item) => {
        return (
          <div className="table-actions">
            <TableButtonEdit segmentId={String(item.id)} refetch={refetch} data={item} />
            <TableButtonDelete handleDeleteFunction={handleDeleteFunction} contactId={String(item.id)} />
          </div>
        );
      },
    },
  ];

  return { columns, data: dataList };
}
