import React from 'react';
import { ThemeLayout } from '../../../layout/themeLayout';
import { PageHeader } from '../../../components/page-headers/page-headers';

import { Main } from '../../../container/styled';
import FeatherIcon from 'feather-icons-react';

import { Link } from 'react-router-dom';
import { image } from '../../../temp';

import { Container, Content } from './styles';
import { useFetch } from '../../../hooks/useFetch';
import { Button } from '../../../components/buttons/buttons';
import { ProductCard } from '../../../container/ecommerce/Style';
import Heading from '../../../components/heading/heading';
import { Col, Row } from 'antd';
interface InterfaceFetchProps {
  name: string;
  description: string;
  status: string;
  image: string;
  url: string;
}

export function UserInteration() {
  const { dataFetch } = useFetch<InterfaceFetchProps[]>({
    baseUrl: 'integrations',
    isLinkProps: false,
  });

  return (
    <ThemeLayout>
      <PageHeader ghost title="Canais de Integração" />

      <Main>
        <Container>
        <Row gutter={40}>
              
          {dataFetch?.map(item => {
            return (
              <Col xl={8} md={12} xxl={6}>
              <ProductCard style={{ marginBottom: 30 }}>
      <figure>
      <img src={`https://api2.cloudzapi.com/uploads${item.image}`} alt={`imagem do ${item.name}`} />
      </figure>
      <figcaption>
       
        <Heading className="product-single-title" as="h5">
          <Link to={`/admin/ecommerce/productDetails/${item.name}`}>{item.name}</Link> 
          <span
                    style={{
                      color: item?.status == 'EM BREVE' ? '#fa8b0c' : '#20C9A5',
                      background: item?.status == 'EM BREVE' ? '#fa8b0c15' : '#E2F4F0',
                      border: `0px solid ${'#E2F4F0'}`,
                      padding: '3px 10px',
                      borderRadius: 40,
                      justifyContent: 'center',
                      alignItems: 'center',
                      marginBottom:'5px',
                      textAlign: 'center',
                      boxSizing: 'border-box',
                      fontVariant: 'tabular-nums',
                      listStyle: 'none',
                      fontFeatureSettings: 'tnum',
                      display: 'inline-block',
                      height: 'auto',
                      fontSize: '11px',
                      lineHeight: '15px',
                      marginLeft: '10px',
                      whiteSpace: 'nowrap',
                      transition: 'all 0.3s',
                    }}
                  >
                    {item?.status}
                  </span>
        </Heading>
        
        <div className="product-single-rating">
        
          
          <span className="total-reviews"> {item.description}</span>
        </div>

        <div className="product-single-action">
          <Button size="small" type="white" className="btn-cart" outlined>
            <FeatherIcon icon="arrow-right" size={14} />
           {/*  <Link to={`${item.url}`}>Ver Integrações</Link> */} 
            <Link to="#">Ver Integrações</Link> 
          </Button>
          <Button size="small" type="primary">
            Adicionar Integração
          </Button>
        </div>
      </figcaption>
    </ProductCard>
    </Col>
             
            );
          })}
</Row>
   
        </Container>
      </Main>
    </ThemeLayout>
  );
}
