import React, { useState } from 'react';
import { Col, Input, Modal } from 'antd';

import { Button } from '../../../../components/buttons/buttons';
import { PlusOutlined } from '@ant-design/icons';
import { Form } from '../ContactForm';

interface IModalAddContactComponent {
  refetch: () => void;
}

export function ButtonAdd({ refetch }: IModalAddContactComponent) {
  const [modalIsOpen, setModalIsOpen] = useState(false);

  const [modalPart, setModalPart] = useState('first-part');

  function OnCloseModal() {
    setModalIsOpen(false);
    setModalPart('first-part');
  }

  return (
    <Col lg={4} xs={24}>
      <div className="table-toolbox-actions">
        <Button size="small" type="primary" onClick={() => setModalIsOpen(true)}>
          <PlusOutlined size={12} /> Adicionar
        </Button>
      </div>

      <Modal
        title="Adicionar"
        visible={modalIsOpen}
        onCancel={() => OnCloseModal()}
        confirmLoading={true}
        footer={null}
        maskClosable={false}
        width={600}
        style={{ marginTop: -60 }}
        bodyStyle={{ padding: 0 }}
      >
        <Form
          formType="post"
          setModalPart={setModalPart}
          ModalPart={modalPart}
          setModalIsOpen={setModalIsOpen}
          refetch={refetch}
        />
      </Modal>
    </Col>
  );
}
