import React from 'react';
import { Avatar } from 'antd';
import { Link } from 'react-router-dom';
import FeatherIcon from 'feather-icons-react';
import { InfoWraper, NavAuth, UserDropDwon } from './auth-info-style';
import { Popover } from '../../popup/popup';
import { Dropdown } from '../../dropdown/dropdown';
import Heading from '../../heading/heading';
import { useAuth } from '../../../hooks/useAuth';
import { useLenguage } from '../../../hooks/useLenguage';
import { api } from '../../../services/api';

const AuthInfo = () => {
  const { signOut, user } = useAuth();
  const { lenguageState, setLenguageState } = useLenguage();

  async function handleLenguage(lenguage: 'en' | 'pt-BR') {
    localStorage.setItem('lenguage', lenguage);
    setLenguageState(lenguage);
    await api.post('update-info', { lenguage });
  }

  const userContent = (
    <UserDropDwon>
      <div className="user-dropdwon">
        <figure className="user-dropdwon__info">
          <img
            style={{ height: '3rem', width: '3rem', borderRadius: '1.5rem' }}
            src="https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_640.png"
          />
          <figcaption>
            <Heading as="h5">{user?.name}</Heading>
            <p style={{ overflow: 'hidden', textOverflow: 'ellipsis', maxWidth: '10rem' }}>{user?.email}</p>
          </figcaption>
        </figure>
        <ul className="user-dropdwon__links">
          <li>
            <Link to="/profile/0">
              <FeatherIcon icon="user" /> Minha Conta
            </Link>
          </li>
          
        </ul>
        <Link className="user-dropdwon__bottomAction" onClick={signOut} to="#">
          <FeatherIcon icon="log-out" /> Sair
        </Link>
      </div>
    </UserDropDwon>
  );

  const country = (
    <NavAuth>
      <Link onClick={() => handleLenguage('en')} to="#">
        <img className="img-pais" src={require('../../../static/img/flag/en.png')} alt="" />
        <span>English</span>
      </Link>
      <Link onClick={() => handleLenguage('pt-BR')} to="#">
        <img className="img-pais" src={require('../../../static/img/flag/pt-BR.png')} alt="" />
        <span>Português</span>
      </Link>
    </NavAuth>
  );

  return (
    <InfoWraper>
      {/* <Message />
      <Notification />

      <Settings />
      <Support /> */}
      <div className="nav-author">
        <Dropdown placement="bottomRight" content={country}>
          <Link to="#" className="head-example">
            <img className="img-pais" src={require(`../../../static/img/flag/${lenguageState}.png`)} alt="" />
          </Link>
        </Dropdown>
      </div>

      <div className="nav-author">
        <Popover placement="bottomRight" content={userContent} action="click">
          <Link to="#" className="head-example">
            <Avatar src="https://cdn0.iconfinder.com/data/icons/user-pictures/100/matureman1-512.png" />
          </Link>
        </Popover>
      </div>
    </InfoWraper>
  );
};

export default AuthInfo;
