import { ColumnsType } from 'antd/lib/table';
import React from 'react';

import { IHomartProps } from '../../types';
import { TableButtonDelete } from './TableButtonDelete';
import { TableButtonEdit } from './TableButtonEdit';
import { TableButtonEvent } from './TableButtonEvent';

interface ITableColumnControllerProps {
  handleDeleteFunction: (tId: string) => Promise<void>;
  refetch: () => void;
  dataList?: IHomartProps[];
}

export function TableColumnController({ handleDeleteFunction, refetch, dataList }: ITableColumnControllerProps) {
  const columns: ColumnsType<IHomartProps> = [
    {
      title: 'Nome',
      dataIndex: 'name',
      sorter: true,
    },

    {
      title: 'Url webhook',
      dataIndex: 'url_webhook',
      sorter: true,
    },

    {
      title: 'Ações',
      dataIndex: 'actions',
      render: (_, item) => {
        console.log(item);
        return (
          <div className="table-actions">
            <TableButtonEdit homartId={String(item.id)} refetch={refetch} data={item} />
            <TableButtonEvent hotmart_id={String(item.id)} />
            <TableButtonDelete handleDeleteFunction={handleDeleteFunction} contactId={String(item.id)} />
          </div>
        );
      },
    },
  ];

  const data: any[] = [];

  dataList?.forEach(item => {
    data.push({
      name: item.name,
      id: item.id,
      url_webhook: item.url_webhook,
    });
  });

  return { columns, data };
}
