import React, { useEffect, useState, createContext, ReactNode } from 'react';
import { LenguagePropsReturn } from './type';
import detectBrowserLanguage from 'detect-browser-language';

import { lenguagePortugues } from './portuguese';
import { lenguageIngles } from './ingles';

type LenguagesProps = 'en' | 'pt-BR';

interface LenguageProviderProps {
  children: ReactNode;
}

interface LenguageContextData {
  lenguageState: LenguagesProps;
  lenguageTexts: LenguagePropsReturn;
  setLenguageState: (event: LenguagesProps) => void;
}

export const LenguageContext = createContext({} as LenguageContextData);

export function LenguageProvider({ children }: LenguageProviderProps) {
  const lenguage = localStorage.getItem('lenguage') as 'pt-BR' | 'en';
  const [lenguageState, setLenguageState] = useState<LenguagesProps>(() => {
    if (lenguage) return lenguage;

    const initialLenguage = detectBrowserLanguage();
    if (initialLenguage === 'pt-BR') return 'pt-BR';
    return 'en';
  });

  const [lenguageTexts, setLenguageTexts] = useState<LenguagePropsReturn>(lenguagePortugues);

  useEffect(() => {
    switch (lenguageState) {
      case 'en':
        setLenguageTexts(lenguageIngles);
        break;

      case 'pt-BR':
        setLenguageTexts(lenguagePortugues);
        break;
    }
  }, [lenguageState]);

  return (
    <LenguageContext.Provider value={{ lenguageState, lenguageTexts, setLenguageState }}>
      {children}
    </LenguageContext.Provider>
  );
}
