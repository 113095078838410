import React, { useState } from 'react';
// import { Modal } from "antd";
import FeatherIcon from 'feather-icons-react';

import { Button } from '../../../../components/buttons/buttons';
import { Modal } from 'antd';
import { Form } from '../ContactForm';

interface ITableButtonEditProps {
  homartId: string;
  refetch: () => void;
  data: IFetchProps;
}

interface IFetchProps {
  name: string;
}

export function TableButtonEdit({ homartId, refetch, data }: ITableButtonEditProps) {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const func = () => {};

  return (
    <>
      <Button className="btn-icon" type="info" onClick={() => setModalIsOpen(true)} to="#" shape="circle">
        <FeatherIcon icon="edit" size={16} />
      </Button>

      <Modal
        title="Editar"
        visible={modalIsOpen}
        onCancel={() => setModalIsOpen(false)}
        confirmLoading={true}
        footer={null}
        maskClosable={false}
        width={600}
        style={{ marginTop: -60 }}
        bodyStyle={{ padding: 0 }}
      >
        {console.log(data.name)}
        <Form
          ModalPart="first-part"
          formType="put"
          setModalPart={func}
          homartId={homartId}
          initialFields={{
            name: data.name,
          }}
          setModalIsOpen={setModalIsOpen}
          refetch={refetch}
        />
      </Modal>
    </>
  );
}
