import styled from 'styled-components';
import { theme } from '../../../../config/theme/themeVariables';

export const Container = styled.div`
  background-color: #fff;
  border-radius: 0.5rem;

  flex: 1;

  header {
    padding: 1rem;
    display: flex;
    flex-direction: column;
    border-bottom: 1px solid ${theme['bg-color-deep']};
    h1 {
      font-size: 1.1rem;
      margin-bottom: 0;
      color: ${theme['heading-color']};
    }

    p {
      margin-bottom: 0;
      color: ${theme['gray-solid']};
    }
  }

  main {
    display: flex;
    flex-direction: column;
    width: 100%;

    .header {
      display: flex;
      padding: 1rem;
      gap: 1rem;
      border-bottom: 1px solid ${theme['bg-color-deep']};

      .firstColumn {
        flex: 3;
      }
      .secondColumn,
      .tercearyColumn {
        flex: 1;
        text-align: end;
      }
    }

    .line {
      display: flex;
      padding: 1rem;
      gap: 1rem;
      border-bottom: 1px solid ${theme['bg-color-deep']};
      background-color: #fff;
      transition: filter 0.1s;

      .firstColumn {
        flex: 3;
      }
      .secondColumn,
      .tercearyColumn {
        flex: 1;
        text-align: end;
        white-space: nowrap;
      }

      &:hover {
        filter: brightness(0.95);
      }
    }
  }
`;

export const Divider = styled.div`
  height: 1px;
  width: 100%;

  background-color: ${theme['bg-color-deep']};
`;
