import React, { useState } from 'react';
import { Container, Content } from './styles';

import { Modal } from 'antd';

import { image } from '../../../temp';

interface VideoCardProps {
  data: {
    id: number;
    createdAt: string;
    updatedAt: string;
    deletedAt: string;
    title: string;
    content: string;
    url: string;
    image: string;
    videohelpCategoryId: number;
  };
}

export function VideoCard() {
  function handleLinkHandling(link: string) {
    const linkIsIncludWatch = link.includes('watch');

    if (!linkIsIncludWatch) {
      const linkSplit = link.split('/');
      return linkSplit[linkSplit.length - 1];
    }

    return '';
  }

  const [videoHelpArticleIdOpen, setVideoHelpArticleIdOpen] = useState('');
  const [videoHelpName, setVideoHelpName] = useState('video');

  function toggleVideoHelpArticle(e: string, title: string) {
    setVideoHelpName(title);
    if (!videoHelpArticleIdOpen) {
      setVideoHelpArticleIdOpen(e);
    } else {
      setVideoHelpArticleIdOpen('');
    }
  }

  const handleCloseModal = () => {
    toggleVideoHelpArticle('', 'video');
  };
  return (
    <>
      {Boolean(videoHelpArticleIdOpen) && (
        <Modal
          visible={Boolean(videoHelpArticleIdOpen)}
          onCancel={handleCloseModal}
          bodyStyle={{}}
          footer={null}
          style={{ height: '500px', minWidth: '650px' }}
        >
          <div style={{ minWidth: '600px', height: '360px', display: 'flex', flexDirection: 'column' }}>
            nome
            <iframe src={videoHelpArticleIdOpen} style={{ width: '600px', height: '336px' }}></iframe>
          </div>
        </Modal>
      )}
      <Container>
        <Content>
          <img src={image} alt="imagem" />

          <div>
            <div>
              <h2>
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Officiis quis molestias architecto itaque!
                Deserunt distinctio at, architecto facilis fugiat enim eligendi atque vero unde, id tenetur, odio quo
                nostrum magnam!
              </h2>
              <button
                onClick={() =>
                  toggleVideoHelpArticle(
                    'https://www.youtube.com/embed/' + handleLinkHandling('https://youtu.be/_ioc6sdgugo'),
                    'nome do video',
                  )
                }
              >
                {'>'}
              </button>
            </div>
            <p>
              Lorem, ipsum dolor sit amet consectetur adipisicing elit. Laboriosam praesentium inventore temporibus
              officia, assumenda facere quia cupiditate veniam enim repudiandae similique ad optio commodi, officiis
              quos, obcaecati architecto provident quod. Lorem ipsum dolor sit amet consectetur adipisicing elit. Unde
              optio accusantium sit dolor. Culpa iure non laborum consectetur consequuntur eos, molestiae praesentium
              enim unde excepturi sapiente velit et harum aliquam. Lorem ipsum dolor sit, amet consectetur adipisicing
              elit. Provident, nobis repellat sit quam natus, earum dignissimos odit voluptates doloremque ipsam fugiat
              accusamus expedita maiores temporibus, ipsa eius magni! Laudantium, ipsum? Lorem ipsum dolor, sit amet
              consectetur adipisicing elit. Doloremque nobis quisquam, veniam cumque qui deserunt fugit quidem molestiae
              quod dicta aspernatur. Eligendi incidunt qui earum ullam soluta fugiat repellat porro.
            </p>
          </div>
        </Content>

        <Content>
          <img src={image} alt="imagem" />

          <div>
            <div>
              <h2>
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Officiis quis molestias architecto itaque!
                Deserunt distinctio at, architecto facilis fugiat enim eligendi atque vero unde, id tenetur, odio quo
                nostrum magnam!
              </h2>
              <button>{'>'}</button>
            </div>
            <p>
              Lorem, ipsum dolor sit amet consectetur adipisicing elit. Laboriosam praesentium inventore temporibus
              officia, assumenda facere quia cupiditate veniam enim repudiandae similique ad optio commodi, officiis
              quos, obcaecati architecto provident quod. Lorem ipsum dolor sit amet consectetur adipisicing elit. Unde
              optio accusantium sit dolor. Culpa iure non laborum consectetur consequuntur eos, molestiae praesentium
              enim unde excepturi sapiente velit et harum aliquam. Lorem ipsum dolor sit, amet consectetur adipisicing
              elit. Provident, nobis repellat sit quam natus, earum dignissimos odit voluptates doloremque ipsam fugiat
              accusamus expedita maiores temporibus, ipsa eius magni! Laudantium, ipsum? Lorem ipsum dolor, sit amet
              consectetur adipisicing elit. Doloremque nobis quisquam, veniam cumque qui deserunt fugit quidem molestiae
              quod dicta aspernatur. Eligendi incidunt qui earum ullam soluta fugiat repellat porro.
            </p>
          </div>
        </Content>

        <Content>
          <img src={image} alt="imagem" />

          <div>
            <div>
              <h2>
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Officiis quis molestias architecto itaque!
                Deserunt distinctio at, architecto facilis fugiat enim eligendi atque vero unde, id tenetur, odio quo
                nostrum magnam!
              </h2>
              <button>{'>'}</button>
            </div>
            <p>
              Lorem, ipsum dolor sit amet consectetur adipisicing elit. Laboriosam praesentium inventore temporibus
              officia, assumenda facere quia cupiditate veniam enim repudiandae similique ad optio commodi, officiis
              quos, obcaecati architecto provident quod. Lorem ipsum dolor sit amet consectetur adipisicing elit. Unde
              optio accusantium sit dolor. Culpa iure non laborum consectetur consequuntur eos, molestiae praesentium
              enim unde excepturi sapiente velit et harum aliquam. Lorem ipsum dolor sit, amet consectetur adipisicing
              elit. Provident, nobis repellat sit quam natus, earum dignissimos odit voluptates doloremque ipsam fugiat
              accusamus expedita maiores temporibus, ipsa eius magni! Laudantium, ipsum? Lorem ipsum dolor, sit amet
              consectetur adipisicing elit. Doloremque nobis quisquam, veniam cumque qui deserunt fugit quidem molestiae
              quod dicta aspernatur. Eligendi incidunt qui earum ullam soluta fugiat repellat porro.
            </p>
          </div>
        </Content>

        <Content>
          <img src={image} alt="imagem" />

          <div>
            <div>
              <h2>
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Officiis quis molestias architecto itaque!
                Deserunt distinctio at, architecto facilis fugiat enim eligendi atque vero unde, id tenetur, odio quo
                nostrum magnam!
              </h2>
              <button>{'>'}</button>
            </div>
            <p>
              Lorem, ipsum dolor sit amet consectetur adipisicing elit. Laboriosam praesentium inventore temporibus
              officia, assumenda facere quia cupiditate veniam enim repudiandae similique ad optio commodi, officiis
              quos, obcaecati architecto provident quod. Lorem ipsum dolor sit amet consectetur adipisicing elit. Unde
              optio accusantium sit dolor. Culpa iure non laborum consectetur consequuntur eos, molestiae praesentium
              enim unde excepturi sapiente velit et harum aliquam. Lorem ipsum dolor sit, amet consectetur adipisicing
              elit. Provident, nobis repellat sit quam natus, earum dignissimos odit voluptates doloremque ipsam fugiat
              accusamus expedita maiores temporibus, ipsa eius magni! Laudantium, ipsum? Lorem ipsum dolor, sit amet
              consectetur adipisicing elit. Doloremque nobis quisquam, veniam cumque qui deserunt fugit quidem molestiae
              quod dicta aspernatur. Eligendi incidunt qui earum ullam soluta fugiat repellat porro.
            </p>
          </div>
        </Content>
      </Container>
    </>
  );
}

export default VideoCard;
