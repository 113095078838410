import styled from 'styled-components';
import { Card } from 'antd';
export const GridContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  gap: 1.4rem;
  width: 100%;
  height: 100%;

  @media (min-width: 1000px) {
    grid-template-columns: repeat(2, 1fr);
  }
`;

export const GridItem = styled.div`
  display: flex;
  align-items: center;
  padding: 1.5rem;

  flex: 1;
  margin-left: none;
  background-color: #fff;
  border-radius: 0.5rem;
  border-color: #dfe4ea;
    border-radius: 0.5rem;
    border-width: thin;
    border-style: solid;

  .textArea {
    display: flex;
    flex-direction: column;
    flex: 1;

    > div {
      display: flex;
      align-items: center;
      gap: 1rem;
      margin-bottom: 1rem;

      div {
        display: flex;
        align-items: center;
        justify-content: center;

        min-height: 3rem;
        min-width: 3rem;

        max-height: 3rem;
        max-width: 3rem;

        border-radius: 1.5rem;
        color: #20c9a5;
        background-color: #e2f4f0;
      }

      h3 {
        margin-bottom: 0;
        font-size:1.2rem;
      }
    }

    p {
      margin-bottom: 0.4rem;
    }
  }

  .buttonArea {
    display: flex;
    flex-direction: column;
    gap: 0.25rem;
    margin-left: auto;

    button {
      height: 40px;
      width: 40px;
      padding: 0 11px;
      background: transparent;
      border: 0 none;
      color: ${({ theme }) => theme['extra-light-color']};

      &:hover {
        &.ant-btn-primary {
          color: ${({ theme }) => theme['primary-color']};
          background: ${({ theme }) => theme['primary-color']}10;
          font-size: 1rem;
        }

        &.ant-btn-info {
          color: ${({ theme }) => theme['info-color']};
          background: ${({ theme }) => theme['info-color']}10;
          font-size: 1rem;
        }

        &.ant-btn-danger {
          color: ${({ theme }) => theme['danger-color']};
          background: ${({ theme }) => theme['danger-color']}10;
          font-size: 1rem;
        }
      }
    }
  }
`;

export const Form = styled.div`
  .ant-picker {
    width: 100%;
  }

  .ant-form-item-has-error .ant-picker {
    border-color: red !important;
  }

  .ant-picker-input input {
    padding: 10px;
  }
  .ant-input[readonly] {
    color: #272b41;
    background-color: #ffffff;
  }
  .ant-select-disabled.ant-select:not(.ant-select-customize-input) .ant-select-selector {
    color: #272b41;
    background: #ffffff;
    cursor: not-allowed;
  }

  .ant-picker-input > input[readonly] {
    color: #272b41;
    background-color: #ffffff;
    border-color: #e3e6ef;
  }

  footer {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-top: 30px;

    button {
      margin-left: 16px;
    }
  }
`;

export const CardWrapper = styled(Card)`
  header {
    display: flex;

    justify-content: space-between;
    align-items: center;
    margin-bottom: 16px;
  }


  .left-header {
    display: flex;
    align-items: center;
    background: red;

    h4 {
      margin: 0;
      margin-right: 16px;
    }

    button {
      margin-right: 16px;
    }
  }

  .actions {
    button {
      margin-left: 14px;
    }
  }
`;

export const Box = styled.div`
  display: flex;
  white-space: nowrap;
  align-items: center;
  background-color: #fff;
  padding: 1rem;
  border-radius: 0.5rem;
  flex: 1;
  margin: 20px;
  gap: 1rem;
  border-radius: 0.5rem;
  border-color: #dfe4ea;
  border-style: solid;
  border-width: thin;

  span {
    width: 4rem;
    height: 4rem;
    border-radius: 0.3rem;

    display: flex;
    align-items: center;
    justify-content: center;

    svg {
      font-size: 1.5rem;
    }
  }

  h2 {
    margin: 0;
    font-size: 1rem;
    font-weight: 500;
    color: #646464;
  }

  h3 {
    font-size: 1.3rem;
    margin: 0;
    font-weight: 600;
  }

  &.serv {
    span {
      background-color: rgba(161, 200, 238, 0.4);
      color: #2c99ff;
    }
  }

  &.check {
    span {
      background-color: rgba(193, 234, 214, 0.4);
      color: #00c182;
    }
  }

  &.power {
    span {
      background-color: rgba(247, 192, 195, 0.4);
      color: #f5222d;
    }
  }
`;
