import React, { useRef, useState } from 'react';
import { Checkbox, Col, Modal, Select } from 'antd';

import { PlusOutlined } from '@ant-design/icons';

import { Button, Input, Form as AntdForm } from 'antd';
import { CardWrapper, FormWrapper } from '../contacts/styles';
import TextArea from 'antd/lib/input/TextArea';
import { api } from '../../services/api';
import { useParams } from 'react-router-dom';
import Cookies from 'js-cookie';

interface IModalAddContactComponent {
  refetch: () => void;
  initialFields: any;
}

export function ChatwoodModal({ refetch, initialFields }: IModalAddContactComponent) {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const params = useParams<any>();
  const formRef = useRef<any>(null);

  const [formData, setFormData] = useState({
    integration_type: initialFields?.integration_type || '',
    cw_enableGroup: initialFields?.cw_enableGroup || 0,
  });

  const token = Cookies.get('whats-front-token');

  function changeValue(key: 'integration_type' | 'cw_enableGroup', value: string | number) {
    setFormData({ ...formData, [key]: value });
  }

  function clearFieldsAndCloseModal() {
    setModalIsOpen(false);
  }

  async function handleSubmit(data: any) {
    setIsLoading(true);

    await api.put(
      '/containers/' + params.id,
      { ...data, ...formData },
      {
        headers: {
          authorization: `Bearer ${token}`,
        },
      },
    );
    refetch();
    setIsLoading(false);
    clearFieldsAndCloseModal();
  }

  return (
    <>
      <Button size="large" type="primary" onClick={() => setModalIsOpen(true)}>
        Integração Chatwoot
      </Button>

      <Modal
        title="Integração Chatwoot"
        visible={modalIsOpen}
        onCancel={() => setModalIsOpen(false)}
        confirmLoading={true}
        footer={null}
        maskClosable={false}
        width={600}
        style={{ marginTop: -60 }}
        bodyStyle={{ padding: 0 }}
      >
        <FormWrapper>
          <AntdForm
            initialValues={initialFields}
            onFinish={handleSubmit}
            name="basic"
            style={{ padding: '30px' }}
            layout="vertical"
            ref={formRef}
          >
            <CardWrapper type="inner" title="Informações Gerais">
              <AntdForm.Item
                label="URL base do Chatwoot: "
                name="cw_baseURL"
                rules={[{ required: true, message: 'Campo obrigatório' }]}
              >
                <Input />
              </AntdForm.Item>

              <AntdForm.Item
                label="Token de Integração:"
                name="cw_cwtoken"
                rules={[{ required: true, message: 'Campo obrigatório' }]}
              >
                <Input />
              </AntdForm.Item>

              <AntdForm.Item label="ID da Conta" name="cw_accountId">
                <Input />
              </AntdForm.Item>

              <AntdForm.Item
                label="Modo de Integração:"
                name="integration_type"
                rules={[{ required: true, message: 'Campo obrigatório' }]}
              >
                <Select onChange={e => changeValue('integration_type', e)}>
                  <Select.Option value="1">Criar nova Caixa de Entrada Automaticamente</Select.Option>
                  <Select.Option value="2">Integrar a uma Caixa de Entrada Existente</Select.Option>
                </Select>
              </AntdForm.Item>

              {formData.integration_type === '1' && (
                <AntdForm.Item
                  label="Nome da Caixa de Entrada"
                  name="cw_inbox_name"
                  rules={[{ required: true, message: 'Campo obrigatório' }]}
                >
                  <Input />
                </AntdForm.Item>
              )}

              {formData.integration_type === '2' && (
                <AntdForm.Item
                  label="Identificador da Caixa de Entrada Existente"
                  name="cw_inbox_identifier"
                  rules={[{ required: true, message: 'Campo obrigatório' }]}
                >
                  <Input />
                </AntdForm.Item>
              )}

              <div style={{ display: 'flex', alignItems: 'baseline', gap: '1rem' }}>
                <p>Habilitar Mensagens de Grupo</p>
                <Checkbox
                  checked={formData.cw_enableGroup === 1}
                  onClick={() => changeValue('cw_enableGroup', formData.cw_enableGroup === 0 ? 1 : 0)}
                />
              </div>

              <AntdForm.Item label="Iniciar conversa como:" name="cw_startConversationAs">
                <Select>
                  <Select.Option value="open">Aberto</Select.Option>
                  <Select.Option value="pendent">Pendente</Select.Option>
                </Select>
              </AntdForm.Item>

              <AntdForm.Item
                rules={[{ required: true, message: 'Campo obrigatório' }]}
                label="Mensagem para Mídias não reconhecidas pelo Chatwoot:"
                name="cw_message"
              >
                <TextArea />
              </AntdForm.Item>
            </CardWrapper>

            <footer style={{ padding: '20px 30px', borderTop: '1px solid #f0f0f0' }}>
              <Button type="default" htmlType="button" onClick={clearFieldsAndCloseModal}>
                Voltar
              </Button>

              <Button type="primary" htmlType="submit" loading={isLoading}>
                Salvar
              </Button>
            </footer>
          </AntdForm>
        </FormWrapper>
      </Modal>
    </>
  );
}
