import React from 'react';
// import { Modal } from "antd";
import FeatherIcon from 'feather-icons-react';

import { Button } from '../../../../components/buttons/buttons';

interface tableButtonEventProps {
  hotmart_id: string;
}

export function TableButtonEvent({ hotmart_id }: tableButtonEventProps) {
  function Navigate() {
    window.open(`events/${hotmart_id}`);
  }
  return (
    <>
      <Button className="btn-icon" type="link" onClick={Navigate} to="#" shape="circle">
        <FeatherIcon icon="eye" size={16} />
      </Button>
    </>
  );
}
