import { ColumnsType } from 'antd/lib/table';
import { IInstanceProps } from '../../types';

interface ITableColumnControllerProps {
  refetch: () => void;
  dataList?: IInstanceProps[];
}

export function TableColumnController({ refetch, dataList }: ITableColumnControllerProps) {
  const columns: ColumnsType<IInstanceProps> = [
    {
      title: 'Usuário',
      dataIndex: 'user.name',
      sorter: true,
    },
    {
      title: 'Nome',
      dataIndex: 'name',
      sorter: true,
    },
    {
      title: 'Nome Interno',
      dataIndex: 'internal_name',
      sorter: true,
    },
    {
      title: 'Status Whatsapp',
      dataIndex: 'status',
      sorter: true,
    },
    {
      title: 'Status Instância',
      dataIndex: 'state',
      sorter: true,
    },
    {
      title: 'Pagamento',
      dataIndex: 'subscription.status',
      sorter: true,
    },
    {
      title: 'Criado Em',
      dataIndex: 'created_at',
      sorter: true,
    },
  ];

  return { columns, data: dataList };
}
