import { Col, Modal } from 'antd';
import React, { useState } from 'react';

import { Form } from '../ContactForm';

interface IModalAddContactComponent {
  refetch: () => void;
}

export function ButtonAdd({ refetch }: IModalAddContactComponent) {
  const [modalIsOpen, setModalIsOpen] = useState(false);

  return (
    <Col lg={4} xs={24}>
      <Modal
        title="Adicionar interação"
        visible={modalIsOpen}
        onCancel={() => setModalIsOpen(false)}
        confirmLoading={true}
        footer={null}
        maskClosable={false}
        width={600}
        style={{ marginTop: -60 }}
        bodyStyle={{ padding: 0 }}
      >
        <Form formType="post" setModalIsOpen={setModalIsOpen} refetch={refetch} />
      </Modal>
    </Col>
  );
}
