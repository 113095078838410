import React, { useState } from 'react';
import { Form, Input, Button } from 'antd';

import Heading from '../../components/heading/heading';

import { AuthWrapper } from './styles';
import AuthLayout from '../../container/profile/authentication/Index';
import { useAuth } from '../../hooks/useAuth';
import { Link } from 'react-router-dom';

function SignIn() {
  const [isLoading, setIsLoading] = useState(false);

  const { signIn } = useAuth();

  async function handleSubmit(data: { email: string; password: string }) {
    setIsLoading(true);
    try {
      await signIn(data);
    } catch (err) {
      // console.log(err);
    }
    setIsLoading(false);
  }

  return (
    <AuthWrapper>
      <div className="auth-contents">
        <Form onFinish={handleSubmit} layout="vertical">
          <Heading as="h3">
            Entre na Sua Conta <span className="color-secondary"></span>
          </Heading>

          <Form.Item
            name="email"
            rules={[{ message: 'Digite um Email Válido!', required: true }]}
            initialValue=""
            label="Email"
          >
            <Input placeholder="Email" />
          </Form.Item>

          <Form.Item name="password" label="Senha">
            <Input.Password placeholder="Senha" />
          </Form.Item>

          <Form.Item style={{ flex: 1 }}>
            <Button className="btn-signin" htmlType="submit" type="primary" size="large">
              {isLoading ? 'Entrando...' : 'Entrar'}
            </Button>

            {/*   <Link to="/#" style={{ marginLeft: '7.98rem' }}>
              Esqueci a senha
            </Link> */}
          </Form.Item>

          <Link to="/forgot" style={{ marginLeft: '9.3rem' }}>
            Esqueceu sua senha?
          </Link>
          <Link to="/signup" style={{ marginLeft: '7.98rem' }}>
            <Button className="btn-signin" htmlType="submit" type="default" size="large">
              Criar uma conta grátis
            </Button>
          </Link>
        </Form>
      </div>
    </AuthWrapper>
  );
}

export default AuthLayout(SignIn);
