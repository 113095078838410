import React from 'react';
import moment from 'moment';
import { ColumnsType } from 'antd/lib/table';

import { ICampaingsProps } from '../../types';
import { TableButtonDelete } from './TableButtonDelete';
import { TableButtonEdit } from './TableButtonEdit';
import { Button } from '../../../../components/buttons/buttons';

import FeatherIcon from 'feather-icons-react';
import { Link } from 'react-router-dom';

interface ITableColumnControllerProps {
  handleDeleteFunction: (tId: string) => Promise<void>;
  refetch: () => void;
  dataList?: ICampaingsProps[];
}

export function TableColumnController({ handleDeleteFunction, refetch, dataList }: ITableColumnControllerProps) {
  const columns: ColumnsType<ICampaingsProps> = [
    {
      title: 'ID',
      dataIndex: 'id',
      sorter: true,
    },

    {
      title: 'Nome',
      dataIndex: 'name',
      sorter: true,
    },

    {
      title: 'Data de criação',
      dataIndex: 'createdAt',
      sorter: true,
    },

    {
      title: 'Ações',
      dataIndex: 'actions',
      render: (_, item) => {
        return (
          <div className="table-actions">
            <Link to={'/admin/flow/campaigns/' + item.flowId}>
              <Button className="btn-icon" type="info" to="#" shape="circle">
                <FeatherIcon icon="settings" size={16} />
              </Button>
            </Link>

            <TableButtonEdit segmentId={String(item.id)} refetch={refetch} data={item} />

            <TableButtonDelete handleDeleteFunction={handleDeleteFunction} contactId={String(item.id)} />
          </div>
        );
      },
    },
  ];

  const data: any[] = [];

  console.log(dataList);

  dataList?.forEach(item => {
    data.push({
      key: item.id,
      id: item.id,
      name: item.name,
      createdAt: moment(item.createdAt).format('DD/MM/YYYY'),
      flowId: item.flowId,
    });
  });

  return { columns, data };
}
