import { Button, Form, Input } from 'antd';
import React, { useState } from 'react';
import toast from 'react-hot-toast';
import { api } from '../../../../services/api';
import { Container } from './styles';
import InputMask from 'react-input-mask';
import styled from 'styled-components';
import { E164Number } from 'libphonenumber-js/types';
/* import 'react-phone-number-input/style.css';
import PhoneInput from 'react-phone-number-input'; */
import pt from 'react-phone-input-2/lang/pt.json';

import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';

interface Props {
  data: {
    name?: string;
    email?: string;
    whatsapp_contact?: null | any;
    whatsapp_notifications?: null | any;
  };
  refetch: () => Promise<void>;
}

const StyledInput = styled(PhoneInput)`
input {
  border: none !important;
  outline: none !important;
}
.react-tel-input .flag-dropdown {
  position: absolute;
  top: 0;
  bottom: 0;
  padding: 0;
  background-color: #f5f5f500 !important;
  border: none !important;
  border-radius: 3px 0 0 3px;
}
`;

export function MyAccount({ data }: Props) {
  console.log(data);
  const [saving, setSaving] = useState(false);
  const [value2, setValue2] = useState("+5537991194030");//data.whatsapp_contact
  const [value, setValue] = useState(data.whatsapp_notifications);

  //setTimeout(() => setValue2('+5537991194030'), 1000)
  //const [value, setValue] = useState()
  function submit(formData: any) {
    setSaving(true);
    api
      .post('update-info', {
        ...formData,
        whatsapp_notifications: value,
      })
      .then(() => toast.success('Salvo com sucesso'))
      .catch(() => toast.error('Não voi possivel salvar'))
      .finally(() => setSaving(false));
  }

  return (
    <Container>
      <header>
        <h1>Minha conta</h1>
        <p>Gerencie aqui sua conta</p>
      </header>

      <main>
     
    
        <Form layout="vertical" onFinish={submit} initialValues={{ name: data.name, email: data.email }}>
          <Form.Item label="Nome Completo" name="name">
            <Input type="text" defaultValue={data.name} />
          </Form.Item>

          <Form.Item label="Email" name="Email">
            <Input type="text" disabled defaultValue={data.email} />
          </Form.Item>

          <Form.Item
             rules={[
              ({ getFieldValue, setFieldsValue }) => ({
                validator(e) {
                  if (value) {
                    setFieldsValue(value);
                    return Promise.resolve();
                  }
                  return Promise.reject(new Error('Campo Obrigatório!'));
                },
              }),
            ]} 
            name="whatsapp_contact"
            label="Contato de whatsapp"
          >
         
      <StyledInput
        enableSearch={true}
        value={value2}
        inputClass="ant-input"
        containerClass="ant-input"
        localization={pt}
        dropdownStyle={{border:"10px"}}
        inputStyle={{border:"none"}}
        buttonStyle={{border:"none", backgroundColor:"#fff"}}
        countryCodeEditable={false}
        onChange={setValue2}
      />
      <p style={{"display":"none"}}>{value2}</p>
          
      </Form.Item>

          <Form.Item
            rules={[
              ({ getFieldValue, setFieldsValue }) => ({
                validator(e) {
                  if (value) {
                    setFieldsValue(value);
                    return Promise.resolve();
                  }
                  return Promise.reject(new Error('Campo Obrigatório!'));
                },
              }),
            ]}
            name="whatsapp_notifications"
            label="Notificações de whatsapp"
          >
             <StyledInput
        enableSearch={true}
        value={value}
        inputClass="ant-input"
        containerClass="ant-input"
        localization={pt}
        dropdownStyle={{border:"10px"}}
        inputStyle={{border:"none"}}
        buttonStyle={{border:"none", backgroundColor:"#fff"}}
        countryCodeEditable={false}
        onChange={setValue}
      />
      <p style={{"display":"none"}}>{value}</p>
          </Form.Item> 

          {/* <Divider /> */}
          {/* <div className="deleteAccount">
          <div>
            <h2>Encerrar conta</h2>
            <p>Excluir sua conta e todos os seus dados</p>
          </div>

          <Button type="primary" size="large" danger>
            Encerrar conta
          </Button>
        </div> */}

          <div className="changes">
            <Button type="primary" loading={saving} htmlType="submit">
              Salvar Alterações
            </Button>

            {/* <Button type="default" size="large"> */}
            {/* Cancelar */}
            {/* </Button> */}
          </div>
        </Form>
      </main>
    </Container>
  );
}
