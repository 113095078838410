import React from 'react';
import { Container, Divider, ItemSelect } from './styles';
import Feather from 'feather-icons-react';
import { useAuth } from '../../../../hooks/useAuth';

interface Props {
  numberPage: number;
  setNumberPage: (e: number) => void;
}

export function LeftMenu({ numberPage, setNumberPage }: Props) {
  const { user } = useAuth();
  return (
    <Container>
      <img
        alt="Prosper Otemuyiwa"
        src="https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_640.png"
      />

      <section>
        <h2>{user?.name}</h2>
        <p>{user?.email}</p>
      </section>

      <Divider />

      <div>
        <ItemSelect onClick={() => setNumberPage(0)} className={numberPage === 0 ? 'active' : ''}>
          <Feather icon="user" /> Minha conta
        </ItemSelect>

        <ItemSelect onClick={() => setNumberPage(1)} className={numberPage === 1 ? 'active' : ''}>
          <Feather icon="lock" /> Trocar senha
        </ItemSelect>
        {user?.plan_type === 1 && (
          <ItemSelect onClick={() => setNumberPage(2)} className={numberPage === 2 ? 'active' : ''}>
            <Feather icon="settings" /> Meu plano
          </ItemSelect>
        )}

        <ItemSelect onClick={() => setNumberPage(3)} className={numberPage === 3 ? 'active' : ''}>
          <Feather icon="users" />
          Histórico de pagamentos
        </ItemSelect>
        <ItemSelect onClick={() => setNumberPage(4)} className={numberPage === 4 ? 'active' : ''}>
          <Feather icon="dollar-sign" />
          Informações de cobrança
        </ItemSelect>
      </div>
    </Container>
  );
}
