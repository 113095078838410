import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  gap: 1rem;
  margin-bottom: 1rem;
`;
export const Content = styled.div`
  background: #ffffff;
  padding: 2rem;
  border-radius: 0.75rem;
  max-height: 600px;

  div {
    width: 400px;
    margin-bottom: 1rem;
    img {
      max-width: 400px;
    }
  }

  div {
    margin-top: 1rem;
    height: 270px;
    display: flex;
    flex-direction: column;
    h2 {
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
    }
    p {
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 4;
      -webkit-box-orient: vertical;
    }
    div {
      height: min-content;
      margin-top: auto;
      margin-bottom: 1rem;
      button {
        border: none;
        background: #2eca84;
        padding: 1rem;
        cursor: pointer;
        border-radius: 0.4rem;
        &:hover {
          opacity: 0.7;
        }
      }
    }
  }
`;
