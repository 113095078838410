import React from 'react';
import { useFetch } from '../../../../hooks/useFetch';
import { Container } from './styles';
import { Pagination } from 'antd';

interface FetchProps {
  amount: string;
  coupon: null | string;
  status: number | string;
  created_at: null | string;
  currency: string;
  customer: null | string;
  id: number;
  interval: string;
  payment_duodate: null;
  invoice_id: null;
  payment_id: string;
  plan: { id: number; name: string; description: string; updated_at: string };
  plan_id: number;
  processor: string;
  product: null | string;
  seller: null | string;
  tax_rates: null | string;
  description: string;
  updated_at: null | string;
  user_id: number;
  paid_at: null | string;
}

export function History() {
  const { dataFetch, currentPage, itensPerPage, setCurrentPage, totalItens } = useFetch<FetchProps[]>({
    baseUrl: 'user-payments',
    isLinkProps: false,
  });

  return (
    <Container>
      <header>
        <h1>Histórico de pagamentos</h1>
      </header>
      <main>
        <div className="header">
          <div className="firstColumn">Detalhes</div>
          <div className="secondColumn">Valor</div>
          <div className="secondColumn">Status</div>
          <div className="tercearyColumn">Data de vencimento</div>
          <div className="tercearyColumn">Data de pagamento</div>
        </div>

        {dataFetch?.map(item => (
          <div className="line">
            <div className="firstColumn">{item.description}</div>
            <div className="secondColumn">
              {new Intl.NumberFormat('pt-br', { currency: item.currency, style: 'currency' }).format(
                Number(item.amount),
              )}
            </div>
            <div className="tercearyColumn">
              <span
                style={{
                  color: item?.status == '3' ? '#20C9A5' : '#ff4d4f',
                  background: item?.status == '3' ? '#E2F4F0' : '#ff4d4f15',
                  border: `0px solid ${'#E2F4F0'}`,
                  padding: '3px 10px',
                  borderRadius: 40,
                  justifyContent: 'center',
                  alignItems: 'center',
                  textAlign: 'center',
                  boxSizing: 'border-box',
                  fontVariant: 'tabular-nums',
                  listStyle: 'none',
                  fontFeatureSettings: 'tnum',
                  display: 'inline-block',
                  height: 'auto',
                  fontSize: '14px',
                  lineHeight: '20px',
                  whiteSpace: 'nowrap',
                  transition: 'all 0.3s',
                }}
              >
                {item?.status == '3' ? 'Pago' : 'Pendente'}
              </span>
            </div>
            <div className="tercearyColumn">
              {item?.payment_duodate && new Date(item?.payment_duodate).toLocaleDateString()}
            </div>
            <div className="tercearyColumn">{item?.paid_at && new Date(item?.paid_at).toLocaleDateString()}</div>
          </div>
        ))}
      </main>
      <div style={{ padding: '1rem' }}>
        <Pagination current={currentPage} pageSize={itensPerPage} total={totalItens} onChange={setCurrentPage} />
      </div>
    </Container>
  );
}
