import React, { useEffect, useRef, useState } from 'react';

import { Button, Input, Form as AntdForm } from 'antd';
import { CardWrapper, FormWrapper } from '../styles';
import FeatherIcon from 'feather-icons-react';

import { api } from '../../../../services/api';
import { IHandleSubmitProps } from '../types';
import toast from 'react-hot-toast';
import { UploadComponent } from '../../../../components/UploadComponent';
import { UploadFile } from 'antd/lib/upload/interface';

// import { MultiSelect } from "../../../components/MultiSelect";

interface IFormInitialFields {
  name: string;
  description: string;
  image: string;
  url: string;
}

interface IFormInteration {
  formType: 'put' | 'post';
  initialFields?: IFormInitialFields;
  refetch: () => void;
  interationId?: string;
  setModalIsOpen: (event: boolean) => void;
}

export function Form({
  formType,
  setModalIsOpen,
  refetch,
  interationId,
  initialFields = { name: '', description: '', image: '', url: '' },
}: IFormInteration) {
  const [isLoading, setIsLoading] = useState(false);

  const [file, setFile] = useState<UploadFile[]>([] as UploadFile[]);

  const formRef = useRef<any>(null);

  function clearFieldsAndCloseModal() {
    setModalIsOpen(false);
  }

  async function handleSubmit(data: IHandleSubmitProps) {
    setIsLoading(true);

    if (!initialFields.image) {
      if (!file[0]) {
        toast.error('É necessário enviar um arquivo.');
        return;
      }
    }

    const formData = {
      name: data.name,
      description: data.description,
      image: file[0].response.data.name || initialFields.image,
      url: data.url,
    };

    try {
      if (formType === 'post') await api.post('/integrations', formData);
      else if (interationId) await api.put(`/integrations/${interationId}`, formData);
      setModalIsOpen(false);
      setFile([]);
      formRef.current.resetFields();
    } catch (err) {
      const error: any = err;
      toast.error(error.response.data.message);
    }

    setIsLoading(false);
    refetch();
  }

  return (
    <FormWrapper>
      <AntdForm
        name="basic"
        onFinish={handleSubmit}
        style={{ padding: '30px' }}
        layout="vertical"
        initialValues={initialFields}
        ref={formRef}
      >
        <CardWrapper type="inner" title="Informações Gerais">
          <AntdForm.Item label="Nome" name="name" rules={[{ required: true, message: 'Nome obrigatório' }]}>
            <Input placeholder="Nome da interação" />
          </AntdForm.Item>
          <AntdForm.Item label="Texto" name="description" rules={[{ required: true, message: 'Texto obrigatório' }]}>
            <Input placeholder="Texto da interação" />
          </AntdForm.Item>
          <AntdForm.Item label="Link" name="url" rules={[{ required: true, message: 'Link obrigatório' }]}>
            <Input placeholder="Link do Tutorial" />
          </AntdForm.Item>
          <UploadComponent setFileList={setFile} maxCount={1} fileList={file} />
          {initialFields.image && !file[0] && (
            <p style={{ fontSize: '13px', marginTop: '0.5rem' }}>
              <FeatherIcon icon="paperclip" size={13} /> {initialFields.image}{' '}
            </p>
          )}
        </CardWrapper>

        <footer style={{ padding: '20px 30px', borderTop: '1px solid #f0f0f0' }}>
          <Button type="default" htmlType="button" onClick={clearFieldsAndCloseModal}>
            Voltar
          </Button>

          <Button type="primary" htmlType="submit" loading={isLoading}>
            Salvar
          </Button>
        </footer>
      </AntdForm>
    </FormWrapper>
  );
}
