import { ColumnsType } from 'antd/lib/table';

import { IPaymentProps } from '../../types';

interface ITableColumnControllerProps {
  dataList?: IPaymentProps[];
}

export function TableColumnController({ dataList }: ITableColumnControllerProps) {
  const columns: ColumnsType<IPaymentProps> = [
    {
      title: 'Usuário',
      dataIndex: 'user.name',
      sorter: true,
    },
    {
      title: 'Descrição',
      dataIndex: 'description',
      sorter: true,
    },
    {
      title: 'Valor',
      dataIndex: 'amount',
      sorter: true,
    },

    {
      title: 'Vencimento em',
      dataIndex: 'payment_duodate',
      sorter: true,
    },
    {
      title: 'Pago em',
      dataIndex: 'paid_at',
      sorter: true,
    },
  ];

  return { columns, data: dataList };
}
