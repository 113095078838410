import React, { useState } from 'react';
import { Form, Input, Button } from 'antd';
import InputMask from 'react-input-mask';
import PhoneInput from 'react-phone-number-input';

import Heading from '../../components/heading/heading';

import { AuthWrapper } from './styles';
import AuthLayout from '../../container/profile/authentication/Index';
import { useAuth } from '../../hooks/useAuth';
import { E164Number } from 'libphonenumber-js/types';
import 'react-phone-number-input/style.css';
import styled from 'styled-components';

const StyledInput = styled(PhoneInput)`
  input {
    border: none !important;
    outline: none !important;
  }
`;

function SignUpAffiliate() {
  const [isLoading, setIsLoading] = useState(false);
  const [value, setValue] = useState<E164Number>();
  const { signUpAffiliate } = useAuth();

  async function handleSubmit(data: {
    email: string;
    password: string;
    name: string;
    instagram: string;
    youtube?: string;
  }) {
    setIsLoading(true);
    if (!value) {
      setIsLoading(false);
      return;
    }

    try {
      await signUpAffiliate({ ...data, whatsapp_contact: value.toString() });
    } catch (err) {
      // console.log(err);
    }
    setIsLoading(false);
  }

  function isValidPassword(password: string) {
    const regex = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])[0-9a-zA-Z$*&@#]{8,}$/;
    return regex.test(password);
  }

  return (
    <AuthWrapper>
      <div className="auth-contents">
        <Form onFinish={handleSubmit} layout="vertical">
          <Heading as="h3">
            Crie sua Conta <span className="color-secondary">de afiliado!</span>
          </Heading>
          <Form.Item rules={[{ message: 'Campo Obrigatório!', required: true }]} name="name" label="Nome">
            <Input placeholder="Nome Completo" />
          </Form.Item>
          <Form.Item
            initialValue=""
            name="email"
            rules={[
              {
                type: 'email',
                message: 'Digite um email válido!',
              },
              {
                required: true,
                message: 'Por favor, insira seu e-mail!',
              },
            ]}
            label="Email"
          >
            <Input placeholder="email@email.com" />
          </Form.Item>

          <Form.Item
            rules={[
              ({ getFieldValue }) => ({
                validator(e) {
                  if (value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(new Error('Campo Obrigatório!'));
                },
              }),
            ]}
            name="whatsapp_contact"
            label="Telefone"
          >
            <StyledInput
              className="ant-input"
              international={false}
              placeholder="Adicione um numero de celular"
              defaultCountry="BR"
              value={value}
              onChange={setValue}
            />
          </Form.Item>
          <Form.Item
            name="instagram"
            rules={[
              {
                required: true,
                message: 'Por favor, insira seu instagram!',
              },
            ]}
            label="Perfil Instagram"
          >
            <Input />
          </Form.Item>
          <Form.Item name="youtube" label="Canal Youtube">
            <Input />
          </Form.Item>
          <Form.Item
            initialValue=""
            name="password"
            label="Senha"
            hasFeedback
            rules={[
              { message: 'Adicione um senha', required: true },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (isValidPassword(value)) {
                    return Promise.resolve();
                  }

                  return Promise.reject(
                    new Error('Crie uma senha com Mínimo 8 caracteres, 1 letra maiúscula e 1 número'),
                  );
                },
              }),
            ]}
          >
            <Input.Password placeholder="Senha" />
          </Form.Item>
          <Form.Item
            name="confirm_password"
            hasFeedback
            label="Confirme sua senha"
            rules={[
              { message: 'Confirme sua senha', required: true },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue('password') === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(new Error('As duas senhas que você digitou não correspondem!'));
                },
              }),
            ]}
          >
            <Input.Password placeholder="Confirmar senha" />
          </Form.Item>
          <Form.Item>
            <Button className="btn-signin" htmlType="submit" type="primary" size="large">
              {isLoading ? 'Criando sua Conta...' : 'Criar Conta'}
            </Button>
          </Form.Item>
        </Form>
      </div>
    </AuthWrapper>
  );
}

export default AuthLayout(SignUpAffiliate);
