import { ColumnsType } from 'antd/lib/table';
import moment from 'moment';
import React from 'react';

import { IhortmartEventsProps } from '../../types';
import { TableButtonDelete } from './TableButtonDelete';
import { TableButtonEdit } from './TableButtonEdit';

interface ITableColumnControllerProps {
  handleDeleteFunction: (id: string) => Promise<void>;
  refetch: () => void;
  dataList?: IhortmartEventsProps[];
}

export function TableColumnController({ handleDeleteFunction, refetch, dataList }: ITableColumnControllerProps) {
  const columns: ColumnsType<IhortmartEventsProps> = [
    {
      title: 'Nome',
      dataIndex: 'name',
      sorter: true,
    },

    {
      title: 'Evento',
      dataIndex: 'event_name',
      sorter: true,
    },

    {
      title: 'Status geral',
      dataIndex: 'status',
      sorter: true,
    },
    {
      title: 'Ações',
      dataIndex: 'actions',
      render: (_, item) => {
        return (
          <div className="table-actions">
            <TableButtonEdit hortmartEventId={String(item.id)} refetch={refetch} data={item} />

            <TableButtonDelete handleDeleteFunction={handleDeleteFunction} contactId={String(item.id)} />
          </div>
        );
      },
    },
  ];

  const data: any[] = [];

  dataList?.forEach(item => {
    if (item.status === 1) {
      data.push({
        id: item.id,
        name: item.name,
        event_name: item.event_name,
        file: item.file,
        message: item.message,
        status: 'ATIVO',
        test_status: item.test_status,
        createdAt: moment(item.createdAt).format('DD/MM/YYYY'),
      });
    }

    if (item.status === 0) {
      data.push({
        id: item.id,
        name: item.name,
        event_name: item.event_name,
        file: item.file,
        message: item.message,
        status: 'INATIVO',
        test_status: item.test_status,
        createdAt: moment(item.createdAt).format('DD/MM/YYYY'),
      });
    }
  });

  return { columns, data };
}
