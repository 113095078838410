import React from 'react';
import moment from 'moment';
import { ColumnsType } from 'antd/lib/table';

import { ISegmentsProps } from '../../types';
import { TableButtonDelete } from './TableButtonDelete';
import { TableButtonEdit } from './TableButtonEdit';

interface ITableColumnControllerProps {
  handleDeleteFunction: (tId: string) => Promise<void>;
  refetch: () => void;
  dataList?: ISegmentsProps[];
}

export function TableColumnController({ handleDeleteFunction, refetch, dataList }: ITableColumnControllerProps) {
  const columns: ColumnsType<any> = [
    {
      title: 'Nome',
      dataIndex: 'name',
      render: (_, data) => <p>{data.nome}</p>,
    },

    {
      title: 'Data',
      dataIndex: 'created_at',
      render: (_, data) => <p>{new Date(data.created_at).toLocaleDateString()}</p>,
    },

    {
      title: 'Valor da Venda ',
      dataIndex: 'amount',
      render: (_, data) => <p>R$ {data.amount.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</p>,
    },
    {
      title: 'Valor da Comissão ',
      dataIndex: 'comission',
      render: (_, data) => <p>R$ {data.comission.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</p>,
    },
  ];

  return { columns, data: dataList };
}
