import styled from 'styled-components';
import { theme } from '../../../../config/theme/themeVariables';

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  background-color: #fff;
  border-radius: 0.5rem;

  width: 23rem;
  height: min-content;

  padding: 2rem 1rem;

  img {
    height: 6rem;
    width: 6rem;

    border-radius: 3rem;
  }

  > section {
    display: flex;
    flex-direction: column;
    margin-top: 2rem;
    text-align: center;

    > h2 {
      margin: 0;
      font-weight: 600;
      color: ${theme['gray-hover']};
    }

    > p {
      color: ${theme['gray-solid']};
    }
  }

  > div {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-top: 1rem;
  }
`;

export const ItemSelect = styled.button`
  display: flex;
  align-items: center;
  gap: 1rem;
  width: 100%;
  background-color: #fff;
  border: none;

  svg {
    height: 1rem;
  }

  color: ${theme['gray-solid']};
  text-decoration: none;
  padding: 0.5rem 1rem;
  border-radius: 0.3rem;

  transition: all 0.1s;

  &:hover {
    filter: brightness(0.97);

    color: ${theme['primary-color']};
  }

  &.active {
    filter: brightness(0.97);
    background-color: #fff;
    color: ${theme['primary-color']};

    &:hover {
      cursor: not-allowed;
    }
  }
`;

export const Divider = styled.div`
  height: 1px;
  width: 100%;

  background-color: ${theme['bg-color-deep']};
`;
