import React from 'react';
import { Row, Col } from 'antd';
import { Link } from 'react-router-dom';
import { Badge, FaqCategoryBox, FaqWrapper, UsercardWrapper } from './styles';
import { PageHeader } from '../../components/page-headers/page-headers';
import { Cards } from '../../components/cards/frame/cards-frame';
import { Main } from '../../container/styled';
import { ThemeLayout } from '../../layout/themeLayout';
import { useFetch } from '../../hooks/useFetch';
import VideoCard from './VideoCard';

type videoFetchProps = {
  id: number;
  createdAt: string;
  updatedAt: string;
  deletedAt: string;
  title: string;
  content: string;
  url: string;
  image: string;
  videohelpCategoryId: number;
};

export function Video() {
  const handleChange = e => {
    console.log(e.target.id);
    e.preventDefault();
    e.target
      .closest('ul')
      .querySelector('a.active')
      .classList.remove('active');
    e.target.classList.add('active');
  };

  const { dataFetch } = useFetch<videoFetchProps[]>({ baseUrl: '/videohelps' });
  return (
    <ThemeLayout>
      <PageHeader ghost title="Videos de ajuda" />
      <Main>
        <Row gutter={25}>
          <Col xxl={6} lg={8} md={10} sm={11}>
            <Col xs={24}>
              <FaqCategoryBox>
                <Cards headless>
                  <Badge className="faq-badge">Buscar por tópicos</Badge>

                  <ul>
                    <li>
                      <Link id="1" className="active primary" onClick={handleChange} to="#a">
                        Using Applications
                      </Link>
                    </li>
                    <li>
                      <Link id="2" className="secondary" onClick={handleChange} to="#">
                        UI Elements
                      </Link>
                    </li>
                    <li>
                      <Link id="3" className="success" onClick={handleChange} to="#">
                        Components
                      </Link>
                    </li>
                    <li>
                      <Link id="4" className="warning" onClick={handleChange} to="#">
                        Build Process
                      </Link>
                    </li>
                    <li>
                      <Link id="5" className="info" onClick={handleChange} to="#">
                        Support Policy
                      </Link>
                    </li>
                    <li>
                      <Link id="6" className="danger" onClick={handleChange} to="#">
                        Accounts & Billing
                      </Link>
                    </li>
                  </ul>
                </Cards>
              </FaqCategoryBox>
            </Col>
          </Col>

          <Col xxl={18} lg={16} md={14} sm={13}>
            <UsercardWrapper>
              <Row gutter={25} style={{ gap: '1rem' }}>
                <VideoCard />
              </Row>
            </UsercardWrapper>
          </Col>
        </Row>
      </Main>
    </ThemeLayout>
  );
}

export default Video;
