import React, { useEffect, useRef, useState } from 'react';

import { Button, Input, Form as AntdForm } from 'antd';
import { CardWrapper, FormWrapper } from '../styles';
import { api } from '../../../../services/api';
import { IHandleSubmitProps } from '../types';
import toast from 'react-hot-toast';
import { Select } from '../../../../components/Select';
import { useFetch } from '../../../../hooks/useFetch';

// import { MultiSelect } from "../../../components/MultiSelect";

interface IFormFaqFields {
  title: string;
  content: string;
  title_en: string;
  content_en: string;
  faq_category_id: string;
}

interface IFormFaq {
  formType: 'put' | 'post';
  initialFields?: IFormFaqFields;
  refetch: () => void;
  interationId?: string;
  setModalIsOpen: (event: boolean) => void;
}

export function Form({
  formType,
  setModalIsOpen,
  refetch,
  interationId,
  initialFields = { title: '', content: '', content_en: '', faq_category_id: '', title_en: '' },
}: IFormFaq) {
  const [isLoading, setIsLoading] = useState(false);

  const formRef = useRef<any>(null);

  function clearFieldsAndCloseModal() {
    setModalIsOpen(false);
  }
  const { dataFetch } = useFetch({ baseUrl: 'faqs-categories' });

  async function handleSubmit(data: IHandleSubmitProps) {
    setIsLoading(true);

    const formData = {
      title: data.title,
      content: data.content,
      title_en: data.title_en,
      content_en: data.content_en,
      faq_category_id: data.faq_category_id,
    };

    try {
      if (formType === 'post') await api.post('/faqs', formData);
      else if (interationId) await api.put(`/faqs/${interationId}`, formData);

      setModalIsOpen(false);
      formRef.current.resetFields();
    } catch (err) {
      const error: any = err;
      toast.error(error.response.data.message);
    }

    setIsLoading(false);
    refetch();
  }

  return (
    <FormWrapper>
      <AntdForm
        name="basic"
        onFinish={handleSubmit}
        style={{ padding: '30px' }}
        layout="vertical"
        initialValues={initialFields}
        ref={formRef}
      >
        <CardWrapper type="inner" title="Informações Gerais">
          <AntdForm.Item label="Título" name="title" rules={[{ required: true, message: 'Título é obrigatório' }]}>
            <Input placeholder="Título da Faq" />
          </AntdForm.Item>

          <AntdForm.Item
            label="Título em inglex"
            name="title_en"
            rules={[{ required: true, message: 'Título em ingles é obrigatório' }]}
          >
            <Input placeholder="Título da Faq em ingles" />
          </AntdForm.Item>

          <AntdForm.Item
            label="Conteudo em ingles"
            name="content_en"
            rules={[{ required: true, message: 'Conteudo é obrigatório' }]}
          >
            <Input placeholder="Conteudo da Faq em ingles" />
          </AntdForm.Item>

          <AntdForm.Item
            label="Conteudo"
            name="content"
            rules={[{ required: true, message: 'Conteudo é obrigatório' }]}
          >
            <Input placeholder="Conteudo da Faq" />
          </AntdForm.Item>

          <Select ApiName="title" fetchUrl="faqs-categories" name="faq_category_id" title="Categoria de Faq" />
        </CardWrapper>

        <footer style={{ padding: '20px 30px', borderTop: '1px solid #f0f0f0' }}>
          <Button type="default" htmlType="button" onClick={clearFieldsAndCloseModal}>
            Voltar
          </Button>

          <Button type="primary" htmlType="submit" loading={isLoading}>
            Salvar
          </Button>
        </footer>
      </AntdForm>
    </FormWrapper>
  );
}
