import React, { createContext, useEffect, useState } from "react";
import toast, { Toaster } from "react-hot-toast";
import { useHistory, useLocation } from "react-router-dom";

import Cookie from "js-cookie";

import { api } from "../../services/api";

import type {
  AuthProviderProps,
  ISignInResponse,
  IUserProps,
  signInCredentials,
  signUpCredentials
} from "./types";

interface AuthContextData {
  signIn: (credentials: signInCredentials) => Promise<void>;
  signUp: (credentials: signUpCredentials) => Promise<void>;
  signUpAffiliate: (credentials: any) => Promise<void>;
  signOut: () => void;
  user: IUserProps | undefined;
}

export const AuthContext = createContext({} as AuthContextData); 

export function AuthProvider({ children }: AuthProviderProps) {
  const history = useHistory();
  const location = useLocation();

  const [user, setUser] = useState<IUserProps | undefined>(() => {
    const userCookie = Cookie.get("whats-front-user");

    if(userCookie) return JSON.parse(userCookie);
    return undefined;
  });

  useEffect(() => {
    const token = Cookie.get('whats-front-token');
    if (token) {
      api.defaults.headers.common.authorization = `Bearer ${token}`;
    }
  }, []);

  const { search } = useLocation();


  async function signIn({ email, password }, redirect?: string) {
    try {
      const { data: { token, user } } = await api.post<ISignInResponse>('/login', {
        email,
        password,
      });


      api.defaults.headers.common.authorization = `Bearer ${token.accessToken}`;

      Cookie.set('whats-front-token', token.accessToken, {
        expires: new Date(Date.now() + 1000 * 60 * 60 * 24), // one day
      });

      Cookie.set('whats-front-user', JSON.stringify(user), {
        expires: new Date(Date.now() + 1000 * 60 * 60 * 24), // one day
      });

      //console.log(user);
      setUser(user);
      toast.success(`Seja bem vindo, ${user.name}!`)

      history.push(redirect ? redirect :'/instances');
    } catch (err: any) {
      toast.error(err.response.data.error);
    };
  }

  
  async function signUpAffiliate({email, password, name, whatsapp_contact, instagram, youtube}){
    try {
      const url = `/register-affiliate`;
      await api.post(url, {
        email,
        utm_tags: search,
        name,
        whatsapp_contact,
        password,
        instagram, youtube
      });

      signIn({email,password}, "/admin/affiliate")

    } catch (err: any) {
      console.log(err.response.data);
      toast.error(err.response.data.message);
    };
  }

  async function signUp({email, password, name, whatsapp_contact}){



      try {
        const url =`/register`
        await api.post(url, {
          email,
          utm_tags: search,
          name,
          whatsapp_contact,
          password,
        });

        signIn({email,password})
  
      } catch (err: any) {
        toast.error(err.response.data.message);
      };


  }


  function signOut() {
    Cookie.remove("whats-front-token");
    history.push("/");
  }

  return (
    <AuthContext.Provider value={{ user, signIn,signUp, signOut, signUpAffiliate }}>
      <Toaster 
        position="top-right"
        reverseOrder={false}
      />
      
      {children}
    </AuthContext.Provider>
  );
}
