import React from 'react';
import { ColumnsType } from 'antd/lib/table';

import { TableButtonDelete } from './TableButtonDelete';
import { TableButtonEdit } from './TableButtonEdit';

interface ITableColumnControllerProps {
  handleDeleteFunction: (tId: string) => Promise<void>;
  refetch: () => void;
  dataList?: any[];
}

export function TableColumnController({ handleDeleteFunction, refetch, dataList }: ITableColumnControllerProps) {
  const columns: ColumnsType<any> = [
    {
      title: 'Nome',
      dataIndex: 'name',
      sorter: true,
    },

    {
      title: 'Descrição',
      dataIndex: 'Description',
      sorter: true,
    },

    {
      title: 'Intervalo',
      dataIndex: 'interval',
      sorter: true,
      render: (_, item) => {
        return (
          <span
            style={{
              color: item.interval === 'month' ? '#20C9A5' : '#FA8B0C',
              background: item.interval === 'month' ? '#E2F4F0' : '#ff4d4f15',
              border: `0px solid ${'#E2F4F0'}`,
              padding: '3px 10px',
              borderRadius: 40,
              justifyContent: 'center',
              alignItems: 'center',
              textAlign: 'center',
              boxSizing: 'border-box',
              fontVariant: 'tabular-nums',
              listStyle: 'none',
              fontFeatureSettings: 'tnum',
              display: 'inline-block',
              height: 'auto',
              fontSize: '14px',
              lineHeight: '20px',
              whiteSpace: 'nowrap',
              transition: 'all 0.3s',
            }}
          >
            {item.interval === 'month' && 'Mensal'}
            {item.interval === 'year' && 'Anual'}
          </span>
        );
      },
    },

    {
      title: 'Ações',
      dataIndex: 'actions',
      render: (_, item) => {
        return (
          <div className="table-actions">
            <TableButtonEdit segmentId={String(item.id)} refetch={refetch} data={item} />
            <TableButtonDelete handleDeleteFunction={handleDeleteFunction} contactId={String(item.id)} />
          </div>
        );
      },
    },
  ];

  return { columns, data: dataList };
}
