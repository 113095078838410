import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
`;
export const Content = styled.div`
  background: #ffffff;
  border-radius: 0.4rem;
  display: flex;
  margin-bottom: 1rem;

  img {
    border-radius: 0.4rem 0 0 0.4rem;
    width: 400px;
  }

  div {
    div {
      margin-left: -1rem;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;

      h2 {
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
      }

      button {
        min-height: 3rem;
        min-width: 3rem;
        margin-right: 2rem;
        background: #2fca85;
        border: none;
        border-radius: 50%;
        cursor: pointer;

        &:hover {
          opacity: 0.8;
        }
      }
    }
    margin-left: 2rem;
    margin-top: 1rem;
    flex-direction: column;

    p {
      margin-top: 1rem;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 5;
      -webkit-box-orient: vertical;
    }
  }
`;
