import React, { useEffect, useRef, useState } from 'react';
import { PageHeader } from '../../components/page-headers/page-headers';
import Cookie from 'js-cookie';
import { Main } from '../../container/styled';
import { FiCheckCircle, FiPower } from 'react-icons/fi';
import { useFetch } from '../../hooks/useFetch';
import { ThemeLayout } from '../../layout/themeLayout';
import { Box, GridContainer, GridItem } from './styles';
import FeatherIcon from 'feather-icons-react';
import { Button } from '../../components/buttons/buttons';
import { HiOutlineQrCode, HiCurrencyDollar, HiOutlineServer } from 'react-icons/hi2';
import { Form, Input, Modal, Spin, Button as AntButton, Tooltip, Empty, Row, Col, Pagination } from 'antd';
import axios from 'axios';
import { api } from '../../services/api';
import toast from 'react-hot-toast';
import { CardWrapper } from '../contacts/styles';
import Alert from '../../components/alerts/alerts';
import { useAuth } from '../../hooks/useAuth';
import { useHistory } from 'react-router-dom';
import { PaimentStep } from '../../components/PaimentStep';

interface InstanceFetchProps {
  api_docs_url: string;
  container_id: string;
  create_at: string;
  created_at: string;
  show_payment: 0 | 1;
  trial_ends_at: string | null;
  id: number;
  payment_status: 'PAID' | 'PENDING';
  internal_name: string;
  json_docs: string;
  license_key: string;
  name: string;
  postman_api_docs_url: string;
  QRCode: null | string;
  state: string;
  status_connection: string;
  updated_at: string;
  subscription: {
    status: string;
  };
}

export function Instances() {
  const [isGenerateQRCodeModal, setIsGenerateQRCodeModal] = useState({ name: '', licenceKey: '', id: 0, state: '' });
  const [isGeneratingQRCode, setIsGeneratingQRCode] = useState(false);
  const [QRCode, setQRCode] = useState('');
  const [QRCodeConected, setQRCodeConected] = useState(false);
  const [deleting, setDeleting] = useState(false);

  const { user } = useAuth();

  useEffect(() => {
    if (isGenerateQRCodeModal.state === 'RUNNING' && QRCodeConected) {
      setTimeout(() => closeGenerateQRCodeModal(), 2000);
    }
  }, [isGenerateQRCodeModal, QRCodeConected]);

  function learnRegExp(value: string) {
    const urlPattern = /(?:https?):\/\/(\w+:?\w*)?(\S+)(:\d+)?(\/|\/([\w#!:.?+=&%!\-\/]))?/;
    return urlPattern.test(value);
  }

  const [modalLoading, setModalLoading] = useState(false);

  const [modalVisualityInstance, setModalVisualityInstance] = useState<InstanceFetchProps | undefined>(undefined);

  const [deleteModalInstanceId, setDeleteModalInstanceId] = useState(0);

  const [isAddModalOpen, setIsAddModalOpen] = useState(false);
  const toogleAddModalOpen = () => setIsAddModalOpen(old => !old);

  const [isEditModalOpen, setIsEditModalOpen] = useState({ id: 0, internal_name: '' });
  function openisEditModalOpenl(internal_name: string, id: number) {
    setIsEditModalOpen({ internal_name, id });
  }

  function closeisEditModalOpenl() {
    setIsEditModalOpen({ internal_name: '', id: 0 });
  }

  const { dataFetch: dataFechUnicContainer, refetch: refechUnicContainer } = useFetch<InstanceFetchProps>({
    baseUrl: 'containers/' + isGenerateQRCodeModal.id,
    isLinkProps: false,
  });

  const token = Cookie.get('whats-front-token');

  const [textQrModal, setTextQrModal] = useState('Clique no Botão abaixo para Gerar o QRCode');
  function openGenerateQRCodeModal(name: string, licenceKey: string, id: number, state: string) {
    setIsGenerateQRCodeModal({ name, licenceKey, id, state });
    setQRCodeConected(false);
  }

  const history = useHistory();

  useEffect(() => {
    if (dataFechUnicContainer?.status_connection === 'open') {
      setQRCodeConected(true);
    }
  }, [dataFechUnicContainer]);

  useEffect(() => {
    let timer: any = null;

    clearTimeout(timer);
    if (dataFechUnicContainer?.status_connection !== 'open' && !!isGenerateQRCodeModal.name) {
      timer = setTimeout(() => refechUnicContainer(), 5000);
    }
  }, [isGenerateQRCodeModal.name, dataFechUnicContainer]);

  async function generateQRCode() {
    setIsGeneratingQRCode(true);
    try {
      const { data } = await axios.get(
        `https://api.cloudzapi.com/${isGenerateQRCodeModal.name}/instance/exportQRCodeBase64`,
        {
          headers: {
            apiKey: isGenerateQRCodeModal.licenceKey,
          },
        },
      );
      setQRCode(data.base64);

      setTimeout(() => {
        setQRCode('');
        setTextQrModal('Seu QRCode expirou, gere outro novamente.');
      }, 20000);
    } catch (err) {
      const error: any = err;
      toast.error(error.response.data.message);
    }
    setIsGeneratingQRCode(false);
  }

  const { dataFetch, refetch, setCurrentPage, itensPerPage, totalItens, currentPage } = useFetch<InstanceFetchProps[]>({
    baseUrl: 'containers',
  });

  function closeGenerateQRCodeModal() {
    setIsGenerateQRCodeModal({ name: '', licenceKey: '', id: 0, state: '' });
    setIsGeneratingQRCode(false);
    setQRCodeConected(false);
    refetch();
    setTextQrModal('Clique no Botão abaixo para Gerar o QRCode');
  }

  const formRef = useRef<any>(null);

  const { dataFetch: meDataFetch, refetch: meRefetch } = useFetch<any>({
    baseUrl: 'me',
    isArray: false,
    isLinkProps: false,
  });

  async function handleDeleteInstance() {
    setDeleting(true);
    if (!deleteModalInstanceId) return;
    try {
      const res = await api.delete('/containers/' + deleteModalInstanceId, {
        headers: {
          authorization: `Bearer ${token}`,
        },
      });
      toast.success(res.data.message);
    } catch (err) {
      const error: any = err;
      toast.error(error?.response?.data?.message ?? 'Não foi possivel deletar');
    }

    setDeleteModalInstanceId(0);
    refetch();
    meRefetch();
    setDeleting(false);
  }

  async function handleAddInstance(data: any) {
    setModalLoading(true);
    try {
      await api.post('/containers', data, {
        headers: {
          authorization: `Bearer ${token}`,
        },
      });

      formRef.current.resetFields();
      toogleAddModalOpen();
      refetch();
      meRefetch();
      toast.success('Instância adicionada com sucesso');
    } catch (err) {
      const error: any = err;
      toast.error(error.response.data.message);
    }
    setModalLoading(false);
  }

  async function handleEditInstance(data: any) {
    setModalLoading(true);
    await api.put('/containers/' + data.id, data, {
      headers: {
        authorization: `Bearer ${token}`,
      },
    });

    formRef.current.resetFields();
    closeisEditModalOpenl();
    refetch();
    meRefetch();
    setModalLoading(false);
  }

  function restartInstance(instanceId: number) {
    api
      .put('/restart-container/' + instanceId)
      .then(res => toast.success('Reiniciada com sucesso'))
      .catch(res => toast.success('Não foi possivel reiniciar'));
  }

  const isExpirado = new Date(meDataFetch?.expire_at || '') < new Date();
  const isCancelado = !meDataFetch?.plan_name;

  const CustomizeRenderEmpty = () => (
    <div style={{ textAlign: 'center' }}>
      <Empty
        description={<p>Clique no botão abaixo para criar sua primeira instância (Conexão com o Whatsapp)</p>}
        image={Empty.PRESENTED_IMAGE_SIMPLE}
      />
    </div>
  );

  async function handleRedirectPay(id: string) {
    const { data } = await api.get(`/container/pay-subscription/${id}`);
    window.open(data.url, '_blank');
  }

  const [paimentOpen, setPaimentOpen] = useState(0);

  return (
    <ThemeLayout>
      {isExpirado && !isCancelado && user?.plan_type === 1 && (
        <Alert type="error" message="Erro" description="Seu plano expirou" />
      )}
      {isCancelado && !!meDataFetch?.plan_name && user?.plan_type === 1 && (
        <Alert type="error" message="Erro" description="Seu plano foi cancelado" />
      )}

      {!!meDataFetch?.show_alert && meDataFetch?.alert_message && (
        <Alert type="warning" message="Atenção!" description={meDataFetch?.alert_message} />
      )}

      <PaimentStep isOpen={!!paimentOpen} onClose={() => setPaimentOpen(0)} />

      <div style={{ display: 'flex', flexWrap: 'wrap', alignItems: 'center', gap: '0rem', padding: '1rem' }}>
        <Box className="serv">
          <span>
            <HiOutlineServer />
          </span>

          <div>
            <h2>Total de instâncias</h2>
            <h3>
              {!dataFetch && '...'} {dataFetch?.length}
            </h3>
          </div>
        </Box>
        <Box className="check">
          <span>
            <FiCheckCircle />
          </span>

          <div>
            <h2>Instâncias conectadas</h2>
            <h3>
              {!dataFetch && '...'}{' '}
              {dataFetch?.filter(instances => instances.status_connection.toUpperCase() === 'OPEN').length}
            </h3>
          </div>
        </Box>
        <Box className="power">
          <span>
            <FiPower />
          </span>

          <div>
            <h2>Instâncias desconectadas</h2>
            <h3>
              {!dataFetch && '...'}
              {dataFetch?.filter(instances => instances.status_connection.toUpperCase() === 'CLOSE').length}
            </h3>
          </div>
        </Box>
      </div>

      <PageHeader
        ghost
        title="Instâncias"
        buttons={[
          <Button type="primary" onClick={toogleAddModalOpen}>
            Criar Instância
          </Button>,
        ]}
      />
      <Main>
        {!dataFetch ||
          (dataFetch?.length <= 0 && (
            <>
              <div
                style={{
                  background: '#FFF',
                  padding: '1rem',
                  display: 'flex',
                  borderRadius: '0.5rem 0.5rem 0 0',
                }}
              >
                Olá, Seja bem-vindo(a)!
              </div>
              <div
                style={{
                  background: '#FFF',
                  padding: '1rem 4rem 4rem 4rem',
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: 'center',
                  borderRadius: ' 0 0 0.5rem 0.5rem',
                }}
              >
                <CustomizeRenderEmpty />
                <Button type="primary" onClick={toogleAddModalOpen}>
                  Criar instância
                </Button>
              </div>
            </>
          ))}
        <GridContainer>
          {dataFetch?.map(item => (
            <GridItem>
              <div className="textArea">
                <div>
                  <div>
                    <FeatherIcon background-color="#20C9A5" icon="smartphone" />
                  </div>
                  <aside>
                    <h3>{item.internal_name}</h3>
                    <aside style={{ fontSize: '0.7rem', fontWeight: 500, color: '#646464' }}>
                      Criada em: {new Date(item.created_at).toLocaleDateString()}
                    </aside>
                  </aside>
                </div>

                <p>
                  <strong style={{ fontSize: '1rem', fontWeight: 500, color: '#646464' }}>Status da Instância:</strong>{' '}
                  <span
                    style={{
                      color: item?.state === 'RUNNING' ? '#20C9A5' : '#ff4d4f',
                      background: item?.state === 'RUNNING' ? '#E2F4F0' : '#ff4d4f15',
                      border: `0px solid ${'#E2F4F0'}`,
                      padding: '3px 10px',
                      borderRadius: 40,
                      justifyContent: 'center',
                      alignItems: 'center',
                      textAlign: 'center',
                      boxSizing: 'border-box',
                      fontVariant: 'tabular-nums',
                      listStyle: 'none',
                      fontFeatureSettings: 'tnum',
                      display: 'inline-block',
                      height: 'auto',
                      fontSize: '14px',
                      lineHeight: '20px',
                      whiteSpace: 'nowrap',
                      transition: 'all 0.3s',
                    }}
                  >
                    {item?.state.toUpperCase() === 'RUNNING' && 'Ativa'}
                    {item?.state.toUpperCase() === 'PAUSED' && 'Pausada'}
                    {item?.state.toUpperCase() === 'STOPPED' && 'Parada'}
                  </span>
                </p>

                <p>
                  <strong style={{ fontSize: '1rem', fontWeight: 500, color: '#646464' }}>Status do Whatsapp:</strong>{' '}
                  <span
                    style={{
                      color: item?.status_connection !== 'close' ? '#20C9A5' : '#ff4d4f',
                      background: item?.status_connection !== 'close' ? '#E2F4F0' : '#ff4d4f15',
                      border: `0px solid ${'#E2F4F0'}`,
                      padding: '3px 10px',
                      borderRadius: 40,
                      justifyContent: 'center',
                      alignItems: 'center',
                      textAlign: 'center',
                      boxSizing: 'border-box',
                      fontVariant: 'tabular-nums',
                      listStyle: 'none',
                      fontFeatureSettings: 'tnum',
                      display: 'inline-block',
                      height: 'auto',
                      fontSize: '14px',
                      lineHeight: '20px',
                      whiteSpace: 'nowrap',
                      transition: 'all 0.3s',
                    }}
                  >
                    {item?.status_connection.toUpperCase() === 'OPEN' && 'Conectado'}
                    {item?.status_connection.toUpperCase() === 'CLOSE' && 'Desconectado'}
                    {item?.status_connection.toUpperCase() === 'CONNECTING' && 'Conectando'}
                    {item?.status_connection.toUpperCase() === 'REFUSED' && 'Perdido'}
                  </span>
                </p>
                {/* <p>
                  <strong style={{ fontSize: '1rem', fontWeight: 500, color: '#646464' }}>Status:</strong>{' '}
                  <span
                    style={{
                      color: item?.subscription?.status === '1' ? '#20C9A5' : '#ff4d4f',
                      background: item?.subscription?.status === '1' ? '#E2F4F0' : '#ff4d4f15',
                      border: `0px solid ${'#E2F4F0'}`,
                      padding: '3px 10px',
                      borderRadius: 40,
                      justifyContent: 'center',
                      alignItems: 'center',
                      textAlign: 'center',
                      boxSizing: 'border-box',
                      fontVariant: 'tabular-nums',
                      listStyle: 'none',
                      fontFeatureSettings: 'tnum',
                      display: 'inline-block',
                      height: 'auto',
                      fontSize: '14px',
                      lineHeight: '20px',
                      whiteSpace: 'nowrap',
                      transition: 'all 0.3s',
                    }}
                  >
                    {item?.subscription?.status === '1' && 'Ativo'}
                    {item?.subscription?.status === '0' && 'Desativo'}
                    {!item?.subscription?.status && 'Desativo'}
                    {/* {item?.subscription?.status === 'CONNECTING' && 'Conectando'} */}
                {/* {item?.subscription?.status === 'REFUSED' && 'Perdido'} */}
                {/* </span> */}
                {/* </p>  */}

                {item.show_payment === 1 && (
                  <>
                    {user?.plan_type.toString() !== '1' && (
                      <p>
                        {item.trial_ends_at && item.payment_status != 'PAID' ? (
                          <>
                            <strong style={{ fontSize: '1rem', fontWeight: 500, color: '#646464' }}>
                              Trial Expira em:
                            </strong>{' '}
                            <span
                              style={{
                                color: new Date(item.trial_ends_at) >= new Date() ? '#20C9A5' : '#ff4d4f',
                                background: new Date(item.trial_ends_at) >= new Date() ? '#E2F4F0' : '#ff4d4f15',
                                border: `0px solid ${'#E2F4F0'}`,
                                padding: '3px 10px',
                                borderRadius: 40,
                                justifyContent: 'center',
                                alignItems: 'center',
                                textAlign: 'center',
                                boxSizing: 'border-box',
                                fontVariant: 'tabular-nums',
                                listStyle: 'none',
                                fontFeatureSettings: 'tnum',
                                display: 'inline-block',
                                height: 'auto',
                                fontSize: '14px',
                                lineHeight: '20px',
                                whiteSpace: 'nowrap',
                                transition: 'all 0.3s',
                                marginRight: '30px',
                              }}
                            >
                              {new Date(item.trial_ends_at) >= new Date()
                                ? new Date(item.trial_ends_at).toLocaleDateString()
                                : 'Expirado'}
                            </span>
                          </>
                        ) : (
                          <>
                            {' '}
                            <strong style={{ fontSize: '1rem', fontWeight: 500, color: '#646464' }}>
                              {' '}
                              Pagamento:
                            </strong>{' '}
                            <span
                              style={{
                                color: item?.subscription?.status === '3' ? '#20C9A5' : '#ff4d4f',
                                background: item?.subscription?.status === '3' ? '#E2F4F0' : '#ff4d4f15',
                                border: `0px solid ${'#E2F4F0'}`,
                                padding: '3px 10px',
                                borderRadius: 40,
                                justifyContent: 'center',
                                alignItems: 'center',
                                textAlign: 'center',
                                boxSizing: 'border-box',
                                fontVariant: 'tabular-nums',
                                listStyle: 'none',
                                fontFeatureSettings: 'tnum',
                                display: 'inline-block',
                                height: 'auto',
                                fontSize: '14px',
                                lineHeight: '20px',
                                whiteSpace: 'nowrap',
                                transition: 'all 0.3s',
                                marginRight: '30px',
                              }}
                            >
                              {item?.subscription?.status === '3' ? 'Pago' : 'Pendente de Pagamento'}
                            </span>
                          </>
                        )}

                        {user?.plan_type.toString() !== '1' && item?.subscription?.status.toString() !== '3' && (
                          <Tooltip title="Pagar instancia">
                            <Button type="primary" onClick={() => handleRedirectPay(String(item.id))}>
                              Pagar
                            </Button>
                          </Tooltip>
                        )}
                      </p>
                    )}
                  </>
                )}

                {/* {user?.plan_type.toString() !== '1' && item?.payment_status === 'PENDING' && (
                  <div style={{ width: '3rem' }}>
                    <Tooltip title="Pagar instancia aqui">
                      <Button type="primary" danger onClick={() => setPaimentOpen(item.id)}>
                        Pagar aqui
                      </Button>
                    </Tooltip>
                  </div>
                )} */}
              </div>

              <div className="buttonArea">
                <Tooltip title="Excluir Instância">
                  <Button
                    className="btn-icon"
                    type="danger"
                    onClick={() => setDeleteModalInstanceId(item.id)}
                    shape="circle"
                  >
                    <FeatherIcon icon="trash-2" size={16} />
                  </Button>
                </Tooltip>
                {/* <Tooltip title="Editar instância">
                  <Button
                    onClick={() => openisEditModalOpenl(item.internal_name, item.id)}
                    className="btn-icon"
                    type="primary"
                    shape="circle"
                  >
                    <FeatherIcon icon="edit" size={16} />
                  </Button>
                </Tooltip> */}
                {/* <Tooltip title="Reiniciar instância">
                  <Button onClick={() => restartInstance(item.id)} className="btn-icon" type="danger" shape="circle">
                    <FeatherIcon icon="refresh-ccw" size={16} />
                  </Button>
                </Tooltip> */}
                <Tooltip title="Ver Detalhes da Instância">
                  <Button
                    onClick={() => history.push('/instances/' + item.id)}
                    className="btn-icon"
                    type="info"
                    shape="circle"
                  >
                    <FeatherIcon icon="eye" size={16} />
                  </Button>
                </Tooltip>

                <Tooltip title="Gerar QRCode">
                  <Button
                    className="btn-icon"
                    type="info"
                    shape="circle"
                    onClick={() => openGenerateQRCodeModal(item.name, item.license_key, item.id, item.state)}
                  >
                    <HiOutlineQrCode size={16} />
                  </Button>
                </Tooltip>
              </div>
            </GridItem>
          ))}
        </GridContainer>

        {totalItens >= 8 && (
          <div style={{ marginTop: '1.5rem', paddingLeft: '0.5rem' }}>
            <Pagination
              current={currentPage}
              total={totalItens}
              pageSize={itensPerPage}
              onChange={e => setCurrentPage(e)}
            />
          </div>
        )}

        {!!isEditModalOpen.internal_name && (
          <Modal
            visible={!!isEditModalOpen.internal_name}
            onCancel={closeisEditModalOpenl}
            bodyStyle={{ padding: 0 }}
            footer={null}
            closable={false}
          >
            <Form
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'stretch',
                justifyContent: 'center',
              }}
              layout="vertical"
              initialValues={{ id: isEditModalOpen.id, internal_name: isEditModalOpen.internal_name }}
              onFinish={handleEditInstance}
              ref={formRef}
            >
              <CardWrapper
                style={{
                  marginTop: '1rem',
                  margin: '20px',
                }}
                type="inner"
                title={`Editar Instância - ${isEditModalOpen?.internal_name}`}
              >
                <Form.Item
                  label="Nome (nome interno):"
                  name="internal_name"
                  rules={[{ required: true, message: 'Nome obrigatório' }]}
                >
                  <Input type="text" />
                </Form.Item>

                <div style={{ position: 'relative' }}>
                  <Form.Item label="URL para Webhook:" name="webhook_url">
                    <Input type="text" style={{ marginTop: '2rem' }} />
                  </Form.Item>
                </div>

                <Form.Item label="" name="id">
                  <Input type="hidden" />
                </Form.Item>
              </CardWrapper>

              <footer
                style={{
                  gap: '5px',
                  padding: '20px',
                  display: 'flex',
                  justifyContent: 'end',
                  borderTop: '1px solid #f0f0f0',
                }}
              >
                <AntButton type="default" htmlType="button" onClick={closeisEditModalOpenl}>
                  Cancelar
                </AntButton>
                <AntButton type="primary" htmlType="submit">
                  {modalLoading ? 'Salvando...' : 'Salvar'}
                </AntButton>
              </footer>
            </Form>
          </Modal>
        )}
        <Modal
          visible={!!modalVisualityInstance}
          onCancel={() => setModalVisualityInstance(undefined)}
          bodyStyle={{ padding: 0 }}
          footer={null}
          width={1200}
          closable={false}
          title={`Instância: ${modalVisualityInstance?.internal_name}`}
          confirmLoading={true}
          maskClosable={false}
          style={{ marginTop: -60 }}
        >
          <Form
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'stretch',
              justifyContent: 'center',
            }}
            layout="vertical"
          >
            <Row gutter={40}>
              <Col
                span={12}
                style={{
                  paddingLeft: '30px',
                  paddingRight: '0px',
                }}
              >
                <CardWrapper
                  style={{
                    marginTop: '1rem',
                    margin: '20px',
                  }}
                  type="inner"
                  title="Detalhes da Instância"
                >
                  <div style={{ marginBottom: '1rem' }}>
                    <strong>Nome da instância:</strong> {modalVisualityInstance?.internal_name}
                  </div>
                  <div style={{ marginBottom: '1rem' }}>
                    <strong>ID:</strong> {modalVisualityInstance?.name}
                  </div>
                  <div style={{ marginBottom: '1rem' }}>
                    <strong>Criada Em:</strong>{' '}
                    {new Date(modalVisualityInstance?.created_at || '').toLocaleDateString()}
                  </div>
                  <div style={{ marginBottom: '1rem' }}>
                    <strong>Status Instância:</strong>{' '}
                    <span
                      style={{
                        color: modalVisualityInstance?.state === 'RUNNING' ? '#20C9A5' : '#ff4d4f',
                        background: modalVisualityInstance?.state === 'RUNNING' ? '#E2F4F0' : '#ff4d4f15',
                        border: `0px solid ${'#E2F4F0'}`,
                        padding: '3px 10px',
                        borderRadius: 40,
                        justifyContent: 'center',
                        alignItems: 'center',
                        textAlign: 'center',
                        boxSizing: 'border-box',
                        fontVariant: 'tabular-nums',
                        listStyle: 'none',
                        fontFeatureSettings: 'tnum',
                        display: 'inline-block',
                        height: 'auto',
                        fontSize: '12px',
                        lineHeight: '20px',
                        whiteSpace: 'nowrap',
                        transition: 'all 0.3s',
                      }}
                    >
                      {modalVisualityInstance?.state.toUpperCase() === 'RUNNING' && 'Ativa'}
                      {modalVisualityInstance?.state.toUpperCase() === 'PAUSED' && 'Pausada'}
                      {modalVisualityInstance?.state.toUpperCase() === 'STOPPED' && 'Parada'}
                    </span>
                  </div>

                  <div style={{ marginBottom: '1rem' }}>
                    <strong>Status Whatsapp:</strong>{' '}
                    <span
                      style={{
                        color: modalVisualityInstance?.status_connection !== 'close' ? '#20C9A5' : '#ff4d4f',
                        background: modalVisualityInstance?.status_connection !== 'close' ? '#E2F4F0' : '#ff4d4f15',
                        border: `0px solid ${'#E2F4F0'}`,
                        padding: '3px 10px',
                        borderRadius: 40,
                        justifyContent: 'center',
                        alignItems: 'center',
                        textAlign: 'center',
                        boxSizing: 'border-box',
                        fontVariant: 'tabular-nums',
                        listStyle: 'none',
                        fontFeatureSettings: 'tnum',
                        display: 'inline-block',
                        height: 'auto',
                        fontSize: '12px',
                        lineHeight: '20px',
                        whiteSpace: 'nowrap',
                        transition: 'all 0.3s',
                      }}
                    >
                      {modalVisualityInstance?.status_connection.toUpperCase() === 'OPEN' && 'Conectado'}
                      {modalVisualityInstance?.status_connection.toUpperCase() === 'CLOSE' && 'Desconectado'}
                      {modalVisualityInstance?.status_connection.toUpperCase() === 'CONNECTING' && 'Conectando'}
                      {modalVisualityInstance?.status_connection.toUpperCase() === 'REFUSED' && 'Perdido'}
                    </span>
                  </div>
                </CardWrapper>
              </Col>
              <Col
                span={12}
                style={{
                  paddingLeft: '0px',
                  paddingRight: '30px',
                }}
              >
                <CardWrapper
                  style={{
                    marginTop: '1rem',
                    margin: '20px',
                  }}
                  type="inner"
                  title="Documentação"
                >
                  <Form.Item label="Api Key:" style={{ marginTop: '1rem' }} name="licence_key">
                    <Input
                      readOnly
                      style={{ backgroundColor: '#f3f7f6' }}
                      type="text"
                      defaultValue={modalVisualityInstance?.license_key}
                    />
                  </Form.Item>

                  <a href={modalVisualityInstance?.api_docs_url} target="_blank">
                    <Form.Item label="URL da Documentação:" name="api_docs_url">
                      <Input
                        style={{ cursor: 'pointer', backgroundColor: '#f3f7f6' }}
                        readOnly
                        type="text"
                        defaultValue={modalVisualityInstance?.api_docs_url}
                      />
                    </Form.Item>
                  </a>
                  {/* <a href={modalVisualityInstance?.json_docs} target="_blank">
                    <Form.Item label="Documentação Postman Json:" name="json_docs">
                      <Input
                        style={{ cursor: 'pointer', backgroundColor: '#f3f7f6' }}
                        readOnly
                        type="text"
                        defaultValue={modalVisualityInstance?.json_docs}
                      />
                    </Form.Item>
                  </a>
                  <a href={modalVisualityInstance?.postman_api_docs_url} target="_blank">
                    <Form.Item label="Documentação Postman Online:" name="postman_api_docs_url">
                      <Input
                        style={{ cursor: 'pointer', backgroundColor: '#f3f7f6' }}
                        readOnly
                        type="text"
                        defaultValue={modalVisualityInstance?.postman_api_docs_url}
                      />
                    </Form.Item>
                  </a> */}
                </CardWrapper>
              </Col>
            </Row>

            <footer
              style={{
                gap: '5px',
                padding: '20px',
                display: 'flex',
                justifyContent: 'end',
                borderTop: '1px solid #f0f0f0',
              }}
            >
              <AntButton type="primary" htmlType="button" onClick={() => setModalVisualityInstance(undefined)}>
                Fechar
              </AntButton>
            </footer>
          </Form>
        </Modal>
        <Modal
          visible={isAddModalOpen}
          onCancel={toogleAddModalOpen}
          bodyStyle={{ padding: 0 }}
          footer={null}
          closable={false}
        >
          <Form
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'stretch',
              justifyContent: 'center',
            }}
            layout="vertical"
            onFinish={handleAddInstance}
            ref={formRef}
          >
            <CardWrapper
              style={{
                marginTop: '1rem',
                margin: '20px',
              }}
              type="inner"
              title="Criar nova instância de Whatsapp"
            >
              <Form.Item
                label="Nome (nome interno):"
                name="internal_name"
                rules={[{ required: true, message: 'Nome obrigatório' }]}
              >
                <Input type="text" />
              </Form.Item>

              <div style={{ position: 'relative' }}>
                <Form.Item label="URL para Webhook:" name="webhook_url">
                  <Input type="text" />
                </Form.Item>
              </div>
            </CardWrapper>

            <footer
              style={{
                gap: '5px',
                padding: '20px',
                display: 'flex',
                justifyContent: 'end',
                borderTop: '1px solid #f0f0f0',
              }}
            >
              <AntButton type="default" htmlType="button" onClick={toogleAddModalOpen}>
                Cancelar
              </AntButton>
              <AntButton type="primary" htmlType="submit">
                {modalLoading ? 'Criando Instância...' : 'Criar Instância'}
              </AntButton>
            </footer>
          </Form>
        </Modal>
        <Modal
          visible={!!deleteModalInstanceId}
          okText={deleting ? 'Excluindo...' : 'Excluir'}
          cancelText="Cancelar"
          onOk={handleDeleteInstance}
          onCancel={() => setDeleteModalInstanceId(0)}
        >
          Você deseja realmente Excluir esta instancia?
        </Modal>
        <Modal
          visible={!!isGenerateQRCodeModal.name}
          okText="Gerar QRCode"
          cancelText="Cancelar"
          onOk={() => isGenerateQRCodeModal.state === 'RUNNING' && generateQRCode()}
          footer={isGenerateQRCodeModal.state === 'RUNNING' ? (QRCodeConected ? null : undefined) : null}
          onCancel={closeGenerateQRCodeModal}
        >
          {isGenerateQRCodeModal.state === 'RUNNING' && isGeneratingQRCode && (
            <div
              style={{
                height: '20rem',
                width: '100%',
                display: 'flex',
                marginTop: '1rem',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <Spin />
            </div>
          )}

          {isGenerateQRCodeModal.state !== 'RUNNING' && (
            <div
              style={{
                height: '20rem',
                width: '100%',
                display: 'flex',
                marginTop: '1rem',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              Não é possível gerar o QR code, pois sua instância está pausada. Clique em "Assinar" para regularizar o
              pagamento, para conseguir se conectar ao Whatsapp
            </div>
          )}

          {isGenerateQRCodeModal.state === 'RUNNING' && !isGeneratingQRCode && !QRCode && !QRCodeConected && (
            <div
              style={{
                height: '20rem',
                width: '100%',
                display: 'flex',
                marginTop: '1rem',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              {textQrModal}
            </div>
          )}

          {isGenerateQRCodeModal.state === 'RUNNING' && QRCodeConected && (
            <div
              style={{
                height: '20rem',
                width: '100%',
                display: 'flex',
                marginTop: '1rem',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              QRCode Conectado
            </div>
          )}

          {isGenerateQRCodeModal.state === 'RUNNING' && !isGeneratingQRCode && QRCode && !QRCodeConected && (
            <div
              style={{
                height: '20rem',
                width: '100%',
                display: 'flex',
                marginTop: '1rem',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <span>Abra o aplicativo do whatsapp e leia o QRCode abaixo para se conectar a esta instância</span>
              <img src={QRCode} />
            </div>
          )}
        </Modal>
      </Main>
    </ThemeLayout>
  );
}
