import React, { useRef, useState } from 'react';

import { Button, Input, Form as AntdForm, Select } from 'antd';
import { CardWrapper, FormWrapper } from '../styles';
import { api } from '../../../../services/api';
import { IHandleSubmitProps } from '../types';

interface IFormInitialFields {
  name: string;
}

interface IFormSegment {
  formType: 'put' | 'post';
  initialFields?: IFormInitialFields;
  refetch: () => void;
  segmentId?: string;
  setModalIsOpen: (event: boolean) => void;
}

export function Form({ formType, setModalIsOpen, refetch, segmentId, initialFields = { name: '' } }: IFormSegment) {
  const [isLoading, setIsLoading] = useState(false);

  const formRef = useRef<any>(null);

  function clearFieldsAndCloseModal() {
    setModalIsOpen(false);
  }

  async function handleSubmit(data: IHandleSubmitProps) {
    setIsLoading(true);

    try {
      if (formType === 'post') {
        await api.post('/segments', { name: data.name });
      } else {
        if (segmentId) await api.put(`/segments/${segmentId}`, { name: data.name });
      }

      setModalIsOpen(false);

      formRef.current.resetFields();
    } catch (err) {
      console.log(err);
    }

    setIsLoading(false);
    refetch();
  }

  return (
    <FormWrapper>
      <AntdForm
        name="basic"
        onFinish={handleSubmit}
        style={{ padding: '30px' }}
        layout="vertical"
        initialValues={initialFields}
        ref={formRef}
      >
        <CardWrapper type="inner" title="Informações Gerais">
          <AntdForm.Item
            label="Webhook URL"
            name="webhook_url"
            rules={[{ required: true, message: 'Nome obrigatório' }]}
          >
            <Input />
          </AntdForm.Item>
        </CardWrapper>

        <footer style={{ padding: '20px 30px', borderTop: '1px solid #f0f0f0' }}>
          <Button type="default" htmlType="button" onClick={clearFieldsAndCloseModal}>
            Voltar
          </Button>

          <Button type="primary" htmlType="submit" loading={isLoading}>
            Salvar
          </Button>
        </footer>
      </AntdForm>
    </FormWrapper>
  );
}
